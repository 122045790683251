.container-wrapper-coach {
  .table-wrapper {
    .coach-table {
      width: 90vw;
      margin-bottom: 20px;
      padding: 10px;
    }
  }

  .select-button-wrapper {
    justify-content: flex-start !important;
    display: flex;
    margin-left: 15px;
  }

  .custom-table-diet {
    margin-top: 20px;

    :where(.css-dev-only-do-not-override-d2lrxs) .ant-table-wrapper.custom-table-diet {
      margin-top: 20px;
    }

    .ant-table-thead>tr>th {
      background-color: #760fb2;
      color: white;
      border-radius: 0px !important;
      font-weight: 700;
    }

    .ant-pagination {

      .ant-pagination-item,
      .ant-pagination-item-active {
        border-color: #760fb2;

        a {
          color: #760fb2;
        }
      }

      .ant-pagination-item-active {
        font-weight: bold;
      }
    }

    .custom-radio .ant-radio {
      transform: scale(1.2);
      /* Make the radio button larger */
      margin-right: 8px;
      /* Add some spacing */
    }

    .custom-radio .ant-radio-inner {
      border-color: #760fb2;
      /* Change the border color */
    }

    .custom-radio .ant-radio-checked .ant-radio-inner {
      background-color: #760fb2;
      /* Change the background color when checked */
    }
  }

  .buttons {
    height: 40px;
    width: 200px;
    background-color: #760fb2;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
  }

  .buttons.select-button.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: black !important;
  }
}