.records-filter-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 16px;
  .filter-options {
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 10px;

    background-color: #efefef;
    border-radius: 16px;
    padding-left: 8px;
    padding-right: 16px;
    padding-top: 34px;
    padding-bottom: 0px;
    .title {
      position: absolute;
      top: 6px;
      left: 16px;
      color: #760fb2;
      font-weight: 700;
      font-size: 16px;
    }
    .search_bar {
      margin-bottom: 1rem;
      .label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 20px;
        color: #760fb2;
        margin-top: 0;
        padding: 0;
        padding-left: 8px;
      }
      .search-input {
        font-family: "Poppins" !important;
        box-sizing: border-box;
        padding: 16px;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.2s ease;

        &:focus {
          border-color: #760fb2;
        }

        &[aria-invalid="true"] {
          border-color: #e53935;
        }
      }
    }
  }
  .rightside-actions-container {
    display: flex;
    align-items: center;
    gap: 8px;
    .refresh-btn {
      width: 40px;
    }
  }
  .apply-btn {
    margin-top: auto;
    margin-bottom: 1rem;
    padding: 10px 16px;
    font-size: 12px;
    height: auto;
  }
}
