.diet-list-data {
    box-shadow: 0 5px 10px rgba(170, 170, 170, .149);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .diet-list-data-header {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-direction: column;
        width: 100%;

        .diet-list-data-heading{
            display: flex;
            flex-direction: column;

            .diet-list-data-title{
                font-weight: 600;
                font-size: 15px;
                letter-spacing: -0.5px;
            }
            .diet-list-data-date{
                font-size: 12px;
                font-weight: 600;
            }
        }

        .view-diet-btn {
            background: white;
            border: none;
            padding: 8px;
            border-radius: 4px;
            border: 1px solid #760fb2;
            cursor: pointer;
            color: #760fb2;
            font-size: 10px;
            font-weight: 600;
        }
    }
}