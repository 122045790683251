.diet-list {
  margin-top: 10px;
  .option-name {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  ul {
    list-style: none;
    padding: 0px;
  }

  li {
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
