.modal {
  background-color: white;
  height: 12%;
  width: 30%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 11%;
  left: 34%;
  z-index: 90;
  border-radius: 1rem;
}
.close-btn {
  position: relative;
  display: flex;
  top: -29%;
  justify-content: center;
  right: -97%;
  background-color: white;
  align-items: center;
  border-radius: 8px;
  width: 1.7rem;
  height: 1.8rem;
  align-items: flex-end;
  font-size: 1.29rem;
  cursor: pointer;
}
.modal-btn {
  cursor: pointer;
  background-color: #4b4ad5;
  border: 1px solid #4b4ad5;
  color: rgb(255, 255, 255) !important;
  padding: 0.5rem 0;
  margin-right: 1.5rem;
  width: 30%;
  border-radius: 0.5rem;
  font-size: 1rem;
}
.modal-content {
  margin: -2rem 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.button-container{
    display: flex;
    /* flex-wrap: nowrap; */
    align-content: space-between;
    gap: 26%;
}
