.diet-input {
  display: flex;
  flex-direction: column;
  gap: 16px;
  // align-items: start;
  width: 100%;

  &:last-child {
    justify-content: center;
  }

  :where(.css-dev-only-do-not-override-1ck3jst).ant-picker {
    width: 100% !important;
  }

  .search_bar {
    .label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: .8rem;
      line-height: 20px;
      color: #760fb2;
      margin-top: 0;
      padding: 0;
    }
  }


}

:where(.css-dev-only-do-not-override-1ck3jst).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1ck3jst).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1ck3jst).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: black !important;
  color: white !important;
  border-radius: 50% !important;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-picker-dropdown .ant-picker-header-view {
  background: #c57ffa !important;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-btn-primary:disabled,
:where(.css-dev-only-do-not-override-1ck3jst).ant-btn-primary.ant-btn-disabled {
  background: #c57ffa !important;
  color: white;
  border-radius: 20px;
}

:where(.css-dev-only-do-not-override-1ck3jst) a {
  color: #760fb2;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-picker-dropdown .ant-picker-today-btn {
  color: #760fb2;
}

.diet-input-container {
  display: flex;
  // flex-direction: column;
  // gap: 150px;
  padding: 15px;
  justify-content: space-between;
  // background-color: red;
}

.assign-title {
  font-size: 18px;
  font-weight: 600;
  // margin-bottom: 20px;
  padding: 15px;
  color: #760fb2;
}

.diet-schedule-container {
  display: flex;
  border-bottom: 1px solid #760fb2;
  margin: 15px 15px;
}

.diet-schedule {
  padding: 5px 25px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;

  &:last-child {
    border-right: none;
  }
}

.diet-add {
  padding: 5px 25px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 120px;
}

.diet-schedule-activate {
  padding: 5px 25px;
  border-bottom: 2px solid #760FB2;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #760FB2;
  background-color: #EBDBF4;
  width: 120px;
  justify-content: center;
}

.meal-timing-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 25px 0px;
  font-size: .8rem;
}

.breakfast-container {
  margin: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 300px;


}

.diet-schedule-options {
  display: flex;
  margin: 15px 15px;
  margin-bottom: 0px;
  border: 1px solid #760FB2;
  // border-left: 1px solid black;
  // border-right: 1px solid black;
}

.diet-schedule-option {
  // border-top: 1px solid black;
  // border-bottom: 1px solid black;
  // border-left: 1px solid black;
  padding: 5px 25px;
  cursor: pointer;
  font-size: 14px;

  &:last-child {
    // border-right: 1px solid black;
  }
}

.diet-option-activate {
  // border: 1px solid black;
  border-bottom: 2px solid #760FB2;
  // border-left: 1px solid black;
  padding: 5px 25px;
  cursor: pointer;
  font-size: 14px;
  // background: #ebdbf4;
  color: #760FB2;
  font-weight: 500;
}

.add-option-button {
  padding: 5px 25px;
  cursor: pointer;
  font-size: 14px;
  color: #760FB2;
  font-weight: 500;
}

.total-diet-counts {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100px;
  background-color: #EBDBF4;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  color: #760FB2;
}

.total-diet-flex {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
}

.total-diet-counts-title {
  font-size: 17px;
  font-weight: 600;
  // width: 100%;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.food-items-container {
  display: grid;
  grid-template-columns: auto auto auto;
  // display: flex;
  // align-items: end;
  gap: 15px;
  margin-top: 20px;
  // min-width: 1500px;
  border: 1px solid #760fb2;
  border-radius: 16px;
  padding: 16px;
  padding-bottom: 75px;
  position: relative;
}

.food-items-main-container {
  // width: 100%;
  overflow-x: auto;
}

.food-items {
  // width: 33%;
  display: flex;
  flex-direction: column;
  // gap: 5px;
  // align-items: center;
  font-size: 0.8rem;
  justify-content: space-around;
  // align-items: center;
  color: #760fb2;

  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 20px;
    color: #760fb2;
    margin-top: 0;
    margin-bottom: -2px;
    padding: 0;
  }
  .search_bar {
    width: 90% !important;
  }
}

.preview-assign-btn {
  border: 1px solid #760fb2;
  padding: 6px 8px;
  border-radius: 4px;
  color: #760fb2;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  height: 40px;
  font-size: 13px;
}

.addFood-item-button-1 {
  border: 1px solid #760fb2;
  padding: 6px 8px;
  border-radius: 4px;
  color: #760fb2;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  height: 30px;
  margin-top: 20px;
  font-size: 12px;
}

.option-container {
  border: 1px solid black;
  padding: 0px 15px;
  padding-top: 5px;
  padding-bottom: 15px;
  margin: 5px 15px;
}

.save-next-button {
  background: #760fb2;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: 40px;
  margin: auto;
}

.save-draft-button {
  background: #760fb2;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: 40px;
  // margin: auto;
}

.save-items-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
}

.hide-meal {
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-range-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: .8rem;
  line-height: 20px;
  color: #760fb2;
}

.hide-meal-title {
  color: black;
  display: flex;
  width: 200px;
  margin-left: 20px;
}

.delete-icons {
  position: absolute;
  bottom: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  gap: 10;

  cursor: pointer;
  color: #666666;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
}

.ant-switch-checked {
  background: #760fb2 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #760fb2 !important;
}

.ant-checkbox-checked {
  border-radius: 50% !important;
}

.ant-radio-checked .ant-radio-inner {
  background-color: #760fb2 !important;
  border-color: #760fb2 !important;
}

.ant-radio-checked {
  background-color: #760fb2 !important
}

.ant-radio:hover .ant-radio-inner {
  border-color: #760fb2 !important;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #760fb2 !important;
}

.ant-select {
  border-radius: 3px !important;
  border: 1px solid #cecece !important;
  outline: none !important;
  color: #760fb2 !important;
  width: 100% !important;
  .ant-select-selector {
    border: none !important;
    width: 100% !important;
    outline: none !important;
  }
  // width: 150px !important;
}

.ant-spin-dot-item {
  background-color: white !important;
}

.createDateRangePicker {
  height: 40px;
  border-radius: 3px;
  margin-top: 8px;
  color: #760fb2 !important;
}

.createDateRangePicker.ant-picker-focused {
  border-color: #760fb2 !important;
  outline: 0 !important;
  box-shadow: 1px 1px 10px 5px rgb(241, 239, 239) !important;
  color: #760fb2 !important;
}

.createDateRangePicker .ant-picker-active-bar {
  background-color: #760fb2 !important;
}

.createDateRangePicker:hover {
  border-color: #760fb2 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #d5c2e0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #d5bbe3 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #760fb2 !important;
}

.ant-select-selection {
  border-radius: 3px;
  height: 39px !important;
  width: 100% !important;
}

.ant-select {
  border-radius: 3px !important;
  border: 1px solid #cecece !important;
  outline: none !important;
  color: #760fb2 !important;
  width: 100% !important;

  .ant-select-selector {
    border: none !important;
    width: 100% !important;
    outline: none !important;
  }
}

:where(.css-dev-only-do-not-override-amq5gd).ant-btn-primary {
  background-color: #760FB2 !important;
}

:where(.css-dev-only-do-not-override-amq5gd) a {
  color: #760FB2 !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #c57ffa !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-picker-focused.ant-picker {
  border-color: #760FB2 !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-picker:hover {
  border-color: #760FB2 !important;
}

.css-rdq5h4-MuiClockPointer-root {
  background-color: #760FB2 !important;
}

.css-umzx0k-MuiClock-pin {
  background-color: #760FB2 !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-modal-root .ant-modal-mask {
  background-color: rgba(82, 81, 81, 0.45);
}

//modal styles

.modal-data {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modal-data-key-value {
  display: flex;
  width: 100%;
}

.modal-data-left {
  display: flex;
  width: 30%;
  gap: 20px;
  font-weight: bold;
}

.add-meal-input {
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 3px;
  transition: 0.5s;
  color: #760fb2;
  width: 120px;
  height: 20px;
  margin-left: 10px;

  &:focus {
    outline: none;
    border: 1px solid #760fb2;
    box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
  }
}

/*Preview & Assign Style */

.diet-preview-upper {
  display: flex;
  flex-direction: column;
}

.diet-preview-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.diet-preview-value {
  border: 1px solid #760fb2;
  margin-left: 10px;
  padding: 4px;
  width: 200px;
  min-height: 25px;
}

.diet-preview-field {
  width: 45%;
  display: flex;
  align-items: center;
}

.mobile-preview-container {
  // background-color: skyblue;
  width: 350px;
  margin: 20px auto;
  height: auto;
  padding-bottom: 10px;
  border: 0.5px solid #f1f1f1;
  border-radius: 10px;
  background-color: #F9F9F9;
}

.preview-tabs {
  display: flex;
  // background-color: red;
  justify-content: space-around;
  margin-top: 30px;
  border-bottom: 0.5px solid #E0E0E0;
  height: 30px;
}

.preview-tab {
  color: #616161;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  width: 33%;
}

.preview-tab-active {
  color: #760FB2;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  border-bottom: 2px solid #760FB2;
  width: 33%;
  font-weight: 700;
}

.calories-preview-container {
  display: flex;
  align-items: center;
  width: 90%;
  background-color: white;
  border-radius: 20px;
  margin: 20px auto;
  padding: 10px;
  min-height: 68px;
  justify-content: space-between;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-progress.ant-progress-circle .ant-progress-text {
  color: #1BBB33;
  font-weight: 700;
}

.calories-count-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.20);
}

.meal-preview-container {
  display: flex;
  flex-direction: column;
  width: 94%;
  background-color: white;
  border-radius: 20px;
  margin: 20px auto;
  height: auto;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.20);
  padding-bottom: 10px;
}

.meal-upper-container {
  display: flex;
  border-bottom: 1px solid #E0E0E0;
  padding: 8px 15px;
  justify-content: space-between;
  align-items: center;
}

.meal-center-container {
  display: flex;
  padding: 8px 15px;
  justify-content: space-between;
}

.meal-tips {
  padding: 8px 15px;
  align-items: center;
  border: 1px solid #E0E0E0;
  width: 85%;
  margin: 20px auto;
  border-radius: 20px;
}

.preview-option {
  border-radius: 8px;
  border: 0.5px solid #E0E0E0;
  background: var(--white, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.20);
  padding: 5px;
  font-size: 12px;
  color: #313131
}

.preview-option-active {
  border-radius: 8px;
  border: 1px solid #3B0859;
  background: rgba(118, 15, 178, 0.08);
  padding: 5px;
  font-size: 12px;
  color: #313131
}

.drawer-icon {
  width: 45px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #E0E0E0;
  margin: 0px auto;
  margin-top: 10px;
}

.chief-cancel-btn {
  border: 1px solid #760fb2;
  padding: 6px 8px;
  border-radius: 4px;
  color: #760fb2;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  height: 40px;
  font-size: 13px;
}

.chief-approve-button {
  background: #760fb2;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: 40px;
}

.chief-approve-button-disable {
  background: #760fb2;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: 40px;
  opacity:0.5;
}

.chief-reject-button {
  background: white;
  padding: 8px 10px;
  border-radius: 4px;
  color: red;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid red;
  height: 40px;
}

.food-items-measure {
  display: flex;
  flex-direction: column;
  // gap: 5px;
  align-items: center;
  font-size: 0.8rem;
  justify-content: space-around;
  align-items: center;
  color: #760fb2
}

.food-items-measure :where(.css-dev-only-do-not-override-amq5gd).ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  // width:200px !important
}