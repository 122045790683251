.desktop-4-child {
  height: 12.5rem;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
.arrow-left1 {
  width: 2rem;
  height: 2rem;
  position: relative;
  z-index: 1;
}
.placeholder-journey {
  width: 11.5rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #868383;
  padding: 0.5rem 1rem;
  gap: 0 0.5rem;
}
.placeholder-select {
  width: 13.5rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #868383;
  padding: 0.6rem 1rem;
  gap: 0 0.5rem;
}
.button-frame10 {
  width: 100%;
  margin-top: 4%;
  margin-bottom: 2%;
  margin-left: 32%;
}
.button-add-cycle {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  /* font-family: Lexend; */
  color: #fff;
  text-align: center;
  padding: 0 2rem;
}
.arrow-instance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.15rem 0 0;
  cursor: pointer;
}
.create-journey {
  position: absolute;
}
.products-list2 {
  margin: 0;
  position: relative;
  font-size: 1.35rem;
  letter-spacing: 0.1px;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.frame-container,
.nested-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 0.625rem;
  margin: 0.5rem 1rem;
}
.frame-container {
  flex-direction: column;
  margin-top: 0.75rem;
  width: 50%;
  max-height: 32rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
  z-index: 9;
  background-color: white;
  position: fixed;
}
.button3 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #fff;
  text-align: center;
}
.button-label,
.button2 {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-label {
  flex: 1;
  gap: 0 0.5rem;
}
.button2 {
  cursor: pointer;
  border: 0;
  padding: 0.75rem 1.25rem;
  background-color: #4b4ad5;
  align-self: stretch;
  z-index: 1;
}
.button-instance,
.desktop-4 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.button-instance {
  width: 13.875rem;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.063rem 0 0;
}
.label {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 149%;
  font-weight: 300;
  padding: 0.5rem 0 0 0;
  font-size: 1.2rem;
}
.care-coin-label {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 149%;
  font-weight: 300;
  font-size: 1.2rem;
}
.btns-container {
  display: flex;
  gap: 1rem;
}
.modal-btns {
  cursor: pointer;
  background-color: white;
  border: 1px solid #4b4ad5;
  color: #4b4ad5 !important;
  padding: 0.5rem 0;
  margin-right: 1.5rem;
  width: 6rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
.modal-btns-active {
  cursor: pointer;
  background-color: #4b4ad5;
  border: 1px solid #4b4ad5;
  color: white !important;
  padding: 0.5rem 0;
  margin-right: 1.5rem;
  width: 6rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}
.care-coins-container {
  margin-top: 1rem;
  width: 84%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  padding: 1rem 1rem;
  border: 1px solid #ececec;
  border-radius: 0.8rem;
  background: #cfcfcf54;
}
.desktop-4 {
  /* width: 100%; */
  position: relative;
  background-color: #fafafb;
  border: 1px solid light grey;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.438rem 4.375rem 50.5rem 1.875rem;
  letter-spacing: normal;
  gap: 1.25rem;
  text-align: left;
  font-size: 1.5rem;
  color: #171725;
  font-family: Poppins;
  margin-left: 11rem;
}
.f-r-a-m-ewrapper {
  width: 40.5rem;
  flex-direction: row;
  padding: 0 1.5rem;
  color: #100604;
  font-family: Lexend;
  margin-left: 2.8rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  box-sizing: border-box;
}
@media screen and (max-width: 1025px) {
  .desktop-4 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 725px) {
  .desktop-4 {
    padding-right: 2.188rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .products-list2 {
    font-size: 1.188rem;
    line-height: 1.813rem;
  }
}
