.diet-plan-pdf-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: Poppins;

    .diet-basic-details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .diet-basic-header {
            display: flex;
            height: 45px;
            justify-content: space-between;
            background: linear-gradient(90deg, #4B4AD5 0%, #A461D8 100%);
            align-items: center;
            padding: 0 10px;

            .diet-pdf-header-title {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                /* 137.5% */
                letter-spacing: 0.1px;
            }
        }

        .diet-patient-details {
            display: flex;
            width: 98%;
            padding:0px 10px;
            justify-content: space-between;
            margin-top: 20px;

            .diet-patient-details-left {
                display: flex;
                align-items: center;
                gap: 10px
            }

            .diet-patient-details-right {
                display: flex;
                align-items: center;
                gap: 10px
            }
        }

        .diet-name-container {
            background-color: #EDDFF7;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 10px;
            border-radius: 6px;
            margin-top: 20px;
            width: 95%;
            margin-left: auto;
            margin-right:auto
        }

        .diet-other-data-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 15px;
            padding: 0px 10px;
            width: 94%;
            margin-left: auto;
            margin-right:auto;

            .diet-other-data {
                display: flex;
                gap: 10px;
                margin-top: 10px;
            }
        }
    }
}

.diet-pdf-btn {
    background: #760fb2;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 28px;
    margin: auto;
    margin-top: 20px;
    font-size: 11px;
}