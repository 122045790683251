.submit_button_div {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel_button {
    background-color: white;
    color: rgb(118, 15, 178);
    border: 1px solid rgb(118, 15, 178);
    margin-left: 10px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.new_template_name {
    .label {
        font-size: 15px;
    }

    .search_bar {
        width: 95%;
    }
}