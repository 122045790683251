.patient-detail {
  padding: 20px;
}

.header {
  background: #f0e7f7;
  padding: 15px;
  border-radius: 5px;
}

.breadcrumb a {
  color: gray;
  text-decoration: none;
  margin: 0 8px;
}

.patient-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(170, 170, 170, 0.149);
  margin-top: 20px;
  padding: 20px;
}

.profile-section {
  display: flex;
  gap: 20px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.vital-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stat {
  display: flex;
  gap: 10px;
  font-size: 0.9rem;
}

.upload-section {
  margin-top: 20px;
}

.upload-button {
  width: 85px;
  height: 100px;
  border: 1px solid #760fb1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.documents-scroll {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;
}

.document-item {
  min-width: 100px;
  text-align: center;
}

.document-image {
  width: 100px;
  height: 75px;
  object-fit: contain;
  border: 1px solid #EBECEE;
  border-radius: 4px;
}

/* Add more styles as needed */