.patient-table {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(118, 15, 178, 0.08);
    overflow: hidden; // Ensures border radius is respected by children
  
    .table-header {
      display: grid;
      grid-template-columns: 60px 2fr 1fr 1.5fr 1.5fr 1.5fr 1fr 1fr 1.5fr;
      padding: 16px 24px;
      background: linear-gradient(to right, #f7f2fc, #f0e7f7);
      border-bottom: 2px solid #e9dcf5;
      position: sticky;
      top: 0;
      z-index: 2;
  
      .header-cell {
        font-weight: 600;
        font-size: 14px;
        color: #4a4a4a;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        white-space: nowrap;
      }
    }

    .cell {
        &.last-active-info {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
      
          .timestamp {
            font-size: 12px;
            color: #888;
          }
        }
      }
  
    .table-body {
      .table-row {
        display: grid;
        grid-template-columns: 60px 2fr 1fr 1.5fr 1.5fr 1.5fr 1fr 1fr 1.5fr;
        padding: 16px 24px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          background-color: #faf6fe;
          transform: translateY(-1px);
          box-shadow: 0 2px 8px rgba(118, 15, 178, 0.05);
        }
  
        .cell {
          font-size: 14px;
          color: #4a4a4a;
          display: flex;
          align-items: center;
          line-height: 1.4;
  
          &.patient-info {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
  
            .name {
              color: #760FB2;
              font-weight: 600;
              font-size: 15px;
            }
  
            .details {
              font-size: 13px;
              color: #666;
            }
          }
  
          &.plan-info {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
  
            .expiry {
              font-size: 12px;
              color: #888;
            }
          }
        }
  
        .action-buttons {
          display: flex;
          gap: 12px;
          align-items: center;
  
          .action-btn {
            padding: 8px;
            border: none;
            background: #f7f2fc;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
  
            &:hover {
              background: #efe5f9;
              transform: translateY(-1px);
            }
  
            img {
              opacity: 0.85;
              transition: opacity 0.2s ease;
            }
  
            &:hover img {
              opacity: 1;
            }
          }
        }
      }
    }
  }