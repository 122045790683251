.textarea-container {
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  margin-bottom: 1rem;
  width: 100%;

  .textarea-label {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    color: #760fb2;
    margin-top: 0;
    padding: 0;
    padding-left: 8px;
    padding-bottom: 4px;
  }

  .textarea-field {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 3px;
    transition: 0.5s;
    color: #760fb2;
    width: 100%;
    padding: 0.6rem 0.4rem;
    &:disabled {
      color: #8ca5a5;
    }

    &:focus {
      outline: none;
      border: 1px solid #760fb2;
      box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    }

    &::placeholder {
      color: #8c8c8c !important;
      transition: 1s;
      font-size: 13px;
      font-family: Poppins;
    }

    &:disabled {
      background-color: #c0cece6e;
      cursor: no-drop;
      pointer-events: none;
    }
    &.error {
      border-color: #e53935;
    }
  }
  .helper-text {
    margin-left: 8px;
    font-size: 12px;
    color: #666;

    &.error-text {
      color: #e53935;
    }
  }
}
