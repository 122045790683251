.patient-report-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 20px;

    .report-header {
        display: flex;
        height: 45px;
        justify-content: space-between;
        background: linear-gradient(90deg, #4B4AD5 0%, #A461D8 100%);
        align-items: center;
        padding: 0 10px;
    }

    .report-title {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        /* 137.5% */
        letter-spacing: 0.1px;
    }

    .patient-details-report {
        display: flex;
        background-color: #F6F6FD;
        padding: 0 10px;
        flex-direction: column;

        .patient-details-first-row {
            display: flex;
            margin: 21px auto 0px auto;
            justify-content: space-between;
            align-items: center;
            width: 97.5%;

            .patient-profile-sec {
                display: flex;
                gap: 13px;
                align-items: center;

                .patient-profile-pic {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .patient-name-age-container {
                    display: flex;
                    flex-direction: column;

                }
            }

            .hc-profile-sec {
                display: flex;
                gap: 13px;

                .hc-profile-name {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .patient-details-second-row {
            display: flex;
            width: 97.5%;
            justify-content: space-between;
            margin: 15px auto;

            .patient-details-second {
                display: flex;
                width: 25%;
                flex-direction: column;
            }
        }

        .hc-month-content {
            display: flex;
            width: 98%;
            margin: 20px auto;
            background-color: white;
            border-radius: 10px;
        }
    }

    .reports-all-tables {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 0 10px;
        margin-top: 20px;

        .table-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 99%;

            .table-heading {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            .table-header {
                background-color: #F1F1F5;
                border-radius: 5px;
                width: 98%;
                display: flex;
                padding: 3px 8px;
            }

            .table-data {
                width: 97%;
                display: flex;
                padding: 0 8px;
                border-bottom: 1px solid #E2E2EA;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: space-between;
            }
        }

        .second-table-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            width: 99%;

            .second-table-header {
                background-color: #F1F1F5;
                border-radius: 5px;
                width: 98%;
                display: flex;
                padding: 4px 8px;
                justify-content: space-between;

                .second-table-header-left {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }

                .second-table-header-right {
                    display: flex;
                }
            }

            .second-table-data {
                width: 97%;
                display: flex;
                padding: 0 8px;
                border-bottom: 1px solid #E2E2EA;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: space-between;
            }
        }
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker .ant-picker-input>input {
        font-size: 8px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker-focused.ant-picker {
        width: 48%;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker {
        width: 48%;
    }
}

.report-btn {
    background: #760fb2;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 28px;
    margin: auto;
    margin-top: 20px;
    font-size: 11px;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
}

.report-container {
    background: white;
    box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    width: 97%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;

    .report-date-title {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .report-title {
            font-size: 15px;
            font-weight: 600;
        }

        .report-date {
            font-size: 12px;
            font-weight: 500;
        }
    }
}