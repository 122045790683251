.loaderAbsolute {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1;
    background-color: rgba(251, 251, 251, 0.8);
    border-radius: 24px 24px 0px 0px;
}

.dashboard-page {
    display: flex;
    width: 98%;
    flex-direction: column;
    padding: 0px 10px;
    background-color: #FAFAFB;
    min-height: 100vh;
    position: relative;

    .dashboard-header {
        display: flex;
        flex-direction: column;
        width: 100%;

        .dashboard-header-name {
            font-size: 24px;
            font-weight: 600;
        }

        .dashboard-header-date {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .dashboard-page-first-row {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 20px;

        @media screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
        }

        .dashboard-page-first-row-left {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            .dashboard-page-first-row-card-wrapper {
                display: flex;
                justify-content: space-between;
                width: 100%;
                gap: 20px;

                .dashboard-page-first-row-card {
                    display: flex;
                    align-items: center;
                    // height: 5px;
                    width: 48%;
                    background-color: #FFF;
                    border: 1px solid #E2E2EA;
                    border-radius: 18px;
                    padding: 20px 20px;
                    gap: 30px;

                    .dashboard-page-first-row-card-details {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .dashboard-page-first-row-right {
            display: flex;
            width: 25.5%;
            border: 1px solid #E2E2EA;
            background-color: #FFF;
            border-radius: 18px;
            flex-direction: column;
            padding: 20px;

            @media screen and (max-width: 1200px) {
                width: 96.5%;
            }

            .dashboard-gender-wrapper {
                display: flex;
                gap: 20px;
                align-items: center;

                .dashboard-gender-details {
                    display: flex;
                    flex-direction: column;
                }

            }
        }
    }

    .dashboard-page-second-row {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 20px;
        height: 490px;

        @media screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
        }

        .dashboard-page-second-row-left {
            width: 69.8%;
            display: flex;
            background-color: #FFF;
            border: 1px solid #E2E2EA;
            border-radius: 18px;
            height: 100%;
            //padding-left: 20px;

            @media screen and (max-width: 1200px) {
                width: 100%
            }

            .dashboard-health-coach-list-container {
                display: flex;
                width: 48%;
                justify-content: space-between;
                padding: 20px;
                height: 100%;
                flex-direction: column;
                height: 92%;

                @media screen and (max-width: 1200px) {
                    height: 92%
                }

                .dashboard-health-coach-list-header {
                    display: flex;
                    gap: 30px;
                }

                .dashboard-health-coach-list {
                    display: flex;
                    flex-direction: column;
                    height: 80%;
                    overflow-y: auto;
                    gap: 10px;

                    .dashboard-health-coach {
                        display: flex;
                        padding: 10px;
                        align-items: center;
                        border-radius: 14px;
                        background: var(--T-BG-100, #FAFAFB);
                        cursor: pointer;
                        gap: 20px
                    }
                }
            }

            .dashboard-selected-health-coach {
                display: flex;
                flex-direction: column;
                border-radius: 14px;
                border: 1px solid var(--T-Text-10, #E2E2EA);
                background: var(--T-BG-100, #FAFAFB);
                width: 48%;
                height: 93%;
                margin: auto;
                margin-right: 20px;

                .dashboard-selected-health-coach-header {
                    display: flex;
                    gap: 30px;
                    padding: 20px;
                    background-color: #F1F1F5;
                    border-radius: 14px 14px 0px 0px;
                    border-bottom: 1px solid var(--T-Text-10, #E2E2EA);
                }

                .dashboard-selected-health-coach-contact {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 20px;
                }

                .dashboard-selected-health-coach-graph {
                    display: flex;
                    align-items: center;
                }

                .dashboard-selected-health-coach-details {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;
                    padding: 0 20px;

                }
            }
        }

        .dashboard-page-second-row-right {
            display: flex;
            flex-direction: column;
            width: 25.5%;
            border: 1px solid #E2E2EA;
            background-color: #FFF;
            border-radius: 18px;
            padding: 20px;

            @media screen and (max-width: 1200px) {
                width: 96.5%
            }

            .dashboard-page-second-row-right-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .dashboard-page-second-row-right-tabs {
                display: flex;
                width: 100%;
                margin-top: 20px;
                border-bottom: 1px solid #E2E2EA;
            }

            .dashboard-page-second-row-right-details {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                width: 100%;

                .dashboard-page-second-row-right-details-count {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }

    .dashboard-page-third-row {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 20px;
        height: 540px;

        @media screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            margin-top: 530px;
        }

        .dashboard-page-third-row-left {
            display: flex;
            flex-direction: column;
            width: 69.8%;
            background-color: #FFF;
            border: 1px solid #E2E2EA;
            border-radius: 18px;
            height: 100%;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            .dashboard-page-third-row-left-header {
                width: 95%;
                display: flex;
                justify-content: space-between;
                padding: 20px 20px 0px 20px;
            }

            .dashboard-page-third-row-left-content {
                padding: 20px 20px 0px 20px;
                width: 95%;
            }
        }

        .dashboard-page-third-row-right {
            display: flex;
            flex-direction: column;
            width: 25.5%;
            border: 1px solid #E2E2EA;
            background-color: #FFF;
            border-radius: 18px;
            padding: 20px;
            height: 93%;

            @media screen and (max-width: 1200px) {
                width: 96.5%;
            }

            .indication-wise-patient-list {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                height: 100%;
                gap: 10px;
                margin-top: 10px;
                padding-right: 10px;
            }
        }
    }

    .dashboard-page-fourth-row {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 20px;
        height: 540px;

        @media screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            margin-top: 580px;
        }

        .dashboard-page-fourth-row-left {
            display: flex;
            flex-direction: column;
            width: 69.8%;
            background-color: #FFF;
            border: 1px solid #E2E2EA;
            border-radius: 18px;
            height: 100%;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            .dashboard-page-fourth-row-left-header {
                width: 95%;
                display: flex;
                justify-content: space-between;
                padding: 20px 20px 0px 20px;
            }

            .dashboard-page-fourth-row-left-content {
                padding: 20px 20px 0px 20px;
                width: 95%;
                display: flex;
                justify-content: space-between;
            }
        }

        .dashboard-page-fourth-row-right {
            display: flex;
            flex-direction: column;
            width: 25.5%;
            border: 1px solid #E2E2EA;
            background-color: #FFF;
            border-radius: 18px;
            padding: 20px;
            height: 93%;

            @media screen and (max-width: 1200px) {
                width: 96.5%;
            }

            .dashboard-page-fourth-row-right-tabs {
                display: flex;
                width: 100%;
                margin-top: 20px;
                border-bottom: 1px solid #E2E2EA;
            }

            .dashboard-page-fourth-row-right-content-list {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 10px;
                gap: 10px;
                overflow-y: auto;

                .dashboard-page-fourth-row-right-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    .dashboard-page-fourth-row-right-content-left {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                    }

                    .dashboard-page-fourth-row-right-content-right {
                        width: 68px;
                        background-color: rgba(237, 138, 0, 0.10);
                        border-radius: 10px;
                        padding: 7px 8px;
                        text-align: center;
                        color: #ED8A00;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}