.patient-details-container {
    display: flex;
    padding: 30px;
    box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    background-color: white;
    height: 100vh;
    max-width: 90%;
    margin: auto;
    overflow-x: auto;
    flex-direction: column;
    gap: 40px;

    .page-header {
        display: flex;
        align-items: center;
        gap: 20px;

        .header-text {
            font-size: 20px;
            font-weight: 600;
        }

        .back-icon {
            cursor: pointer;
            height: 20px;
            width: auto;
        }
    }

    .patient-details-content {
        display: flex;

        .patient-image-container {
            display: flex;
            flex-direction: column;
            height: 200px;

            .patient-img {
                height: 100px;
                width: 100px;
            }

            .patient-size {
                display: flex;
                gap: 40px;
                margin-top: 20px;

                .patient-height {
                    color: rgb(193, 190, 190);
                    font-weight: 500;
                    font-size: 12px;
                }
            }
        }

        .patient-content {
            display: flex;
            height: 200px;

            .patient-content-left {
                display: flex;
                flex-direction: column;
                margin-left: 30px;

                .patient-name {
                    font-weight: 600;
                    font-size: 18px;
                }

                .patient-age {
                    color: #515151;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                }

                .patient-left-bottom {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-top: 15px;
                }
            }

            .vertical-line {
                border: 1px solid lightgray;
                height: 70px;
                margin-left: 50px;
            }
        }
    }

}