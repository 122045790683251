.diet-adherence-container {
    margin-top: 15px;
    box-shadow: 0 5px 10px rgba(170, 170, 170, .149);
    padding: 20px;
}

.diet-plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.diet-plan-heading{
    display: flex;
    flex-direction: column;
}

.diet-plan-date{
    font-size: 12px;
    font-weight: 600;
}
.diet-plan-title{
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.5px;
}

.meal-name{
    font-size: 15px;
    font-weight: 600;
    color: #760fb2;
    letter-spacing: -0.5px;
}

.copy-button {
    background: #760fb2;
    padding: 8px 10px;
    border-radius: 4px;
    color: #fff;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 30px;
    margin: auto;
  }