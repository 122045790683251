.search_bar {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 8px;
    width: 80%;
    color: #760fb2;
    font-family: "Poppins";
  
    input {
      color: #c0cece;
      padding: 0.4rem;
    //   width: 100%;
  
      &:hover {
        border:1px solid #cecece;
        // background: linear-gradient(white, white) padding-box,
        //   linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
        //     border-box !important;
        fill: #760fb2;
        transition: 0.5s;
        color: #760fb2 !important;
        border-image-slice: 1;
      }
  
      &::placeholder {
        color: #8c8c8c !important;
        transition: 1s;
        font-size: 13px;
        font-family: "Poppins";
      }
  
      &:disabled {
        background-color: #c0cece6e;
        cursor: no-drop;
        pointer-events: none;
      }
    }
  
    .search-input {
      background: #ffffff;
      border: 1px solid #cecece;
      border-radius: 3px;
      transition: 0.5s;
      color: #760fb2;
      width: 100%;
      height: 25px;
      &:disabled {
        color: #8ca5a5;
      }
      @media screen and (min-width: 300px) and (max-width: 501px) {
      }

      &:focus{
        outline: none;
        border: 1px solid #760fb2;
        box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
      }
    }
  
    .search-input-error {
      background: #ffffff;
      border: 1px solid #ef4444;
      border-radius: 3px;
      outline: none;
      transition: 0.5s;
      color: #760fb2;
    }
  
    input {
      color: #c0cece;
      font-size: 14px;
      padding-left: .8rem;
  
      @media screen and (min-width: 501px) and (max-width: 1050px) {
        font-size: 12px;
      }
    }
  
    input[type="text"],
    input[type="number"] {
      color: #760fb2 !important;
      font-family: "Century Gothic" !important;
  
      &::placeholder {
        color: #c0cece !important;
        transition: 1s;
      }
    }
  
    input[type="text"],
    input[type="number"] {
      &:hover {
        color: #760fb2 !important;
  
        &::placeholder {
          color: #c0cece !important;
        }
      }
      &:disabled {
        background-color: #c0cece6e;
      }
    }
  }
  
  .label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 20px;
    color: #760fb2;
  }
  
  input[type="text"],
  input[type="number"],
  select {
    color: #760fb2;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  input[type="text"]:focus,
  input[type="number"]:focus {
    border: 1px solid #cecece;
    // background: linear-gradient(white, white) padding-box,
    //   linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
    //     border-box !important;
    outline: none !important;
  }