.pending-plan-approval-list{
    display: flex;
    flex-direction: column;
    width: 100%;

    
}

.loaderAbsolute {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(251, 251, 251, 0.8);
    border-radius: 24px 24px 0px 0px;
}