.left-container {
    flex: 1;
    flex-direction: column;
    gap: 1.875rem 0;
    min-width: 27.438rem;
    max-width:50%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }