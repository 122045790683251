.file-upload-container {
  font-family: Arial, sans-serif;

  .file-format-info {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #760fb2;
    margin-left: 8px;
    font-weight: 600;
  }

  .file-upload-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed #ccc;
    padding: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;

    .upload-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .icon {
        font-size: 2rem;
        color: #8a2be2;
      }

      p {
        margin-top: 8px;
        font-size: 0.9rem;
        text-align: center;
        color: #333;
      }
    }

    .divider {
      font-size: 1rem;
      color: #333;
      margin: 0 16px;
    }

    .browse-file {
      display: inline-block;
      background-color: #8a2be2;
      color: #fff;
      padding: 8px 16px;
      font-size: 0.9rem;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
    }

    .camera-view {
      display: flex;
      flex-direction: column;
      align-items: center;

      .video-feed {
        width: 100%;
        max-height: 300px;
        margin-bottom: 8px;
        border-radius: 8px;
        background-color: #000;
      }

      .snapshot-button {
        background-color: #8a2be2;
        color: #fff;
        padding: 8px 16px;
        font-size: 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    &.error {
      border-color: #e53935;
    }
  }

  .helper-text {
    margin-left: 8px;
    font-size: 12px;
    color: #666;

    &.error-text {
      color: #e53935;
    }
  }

  .file-name {
    margin-top: 1rem;
    // border: 1px dashed #ccc;
    padding: 0.7rem 1rem;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
}
