.custom_label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: .7rem;
    line-height: 20px;
    color: #760fb2;
    margin-top: 0;
    padding: 0;
}

.nutrient_input {
    border: 1px solid #cecece;
    width: 80%;
    padding: 10px;
    border-radius: 16px;

    .search_bar {
        width: 85% !important;
    }
}

.custom-warning-modal {
    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        padding: 0;
        border-radius: 16px;
        min-height: 32vh;
        width: 65vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 16px;
        height: 90%;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    color: #760fb2;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}