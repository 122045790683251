.diet-adherence-heading {
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.5px;
}

.diet-adherence-list {
    margin-top: 18px;
}

.diet-adherence-title {
    font-size: 15px;
    font-weight: 600;
    color: #760fb2;
    letter-spacing: -0.5px;
}

.diet-allergy-list {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.5px;
}

.diet-allergy-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.add-allergy-button {
    border: 1px solid #760fb2;
    padding: 6px 8px;
    border-radius: 4px;
    color: #760fb2;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    height: 25px;
    font-size: 12px;
}

.allergy-input {
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 3px;
    transition: 0.5s;
    color: #760fb2;
    width: 50%;
    height: 32px;
    padding-left: 10px;

    &:disabled {
        color: #8ca5a5;
    }

    @media screen and (min-width: 300px) and (max-width: 501px) {}

    &:focus {
        outline: none;
        border: 1px solid #760fb2;
        box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    }
}

.diet-adherence-heading {
    display: flex;
    justify-content: space-between;
}

.add-allergy-button-disable {
    border: none;
    padding: 6px 8px;
    border-radius: 4px;
    color: rgb(160, 160, 160);
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: no-drop;
    background-color: #f2eded;
    height: 25px;
    font-size: 12px;
}

// .truncate-text {
//    // width: 70%;
//    // background: red;
//     overflow: hidden;
//    //white-space: nowrap;
//     text-wrap: wrap;
//     list-style: disc;
//   }

.allergy-list {
    width: 80%;
    overflow: hidden;
}

.allergy-list li {
    overflow-wrap: break-word;
    list-style: disc;
}

.diet-allergy-container {
    box-shadow: 0 5px 10px rgba(170, 170, 170, .149);
    padding: 20px;
    width: 300px;
}