.adherence-graph {
    // display: grid;
    height: 100%;
    gap: 50px;

    .diet-adherence-container{
        display: flex;
        flex-direction: column;
        min-width: 440px;
        max-height: 350px;

        .diet-adherence-header{
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 20px;

            .diet-adherence-filter{
                display: flex;
                gap: 10px;
            }
            .diet-adherence-heading{
                font-weight: 600;
                font-size: 15px;
                letter-spacing: -0.5px;
            }
        }
    }
}