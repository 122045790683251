$spinner-default-color: #3498db;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  &.small {
    width: 1rem; // 4px in Tailwind terms
    height: 1rem;
  }

  &.medium {
    width: 2rem; // 8px in Tailwind terms
    height: 2rem;
  }

  &.large {
    width: 3rem; // 12px in Tailwind terms
    height: 3rem;
  }

  &.colored {
    border-color: $spinner-default-color transparent transparent transparent;
  }
}
