.shape3 {
  align-self: stretch;
  height: 25.5rem;
  position: relative;       
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  box-sizing: border-box;
  display: none;
}
.f-r-a-m-e-f-r-a-m-e-products-l {
  width: 13.563rem;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1.5rem;
  box-sizing: border-box;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1rem;
  color: #171725;
}
.f-r-a-m-e-f-r-a-m-e-button {
  align-self: stretch;
  height: 0.063rem;
  position: relative;
  border-top: 1px solid #e2e2ea;
  box-sizing: border-box;
  z-index: 1;
}
.f-r-a-m-e-group-frame-products {
  align-self: stretch;
  height: 2.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.25rem 0;
}
.disabled {
  background-color: #d0cfe3 !important;
  border: 1px solid #ccccdd !important;
  color: rgb(255, 255, 255) !important;
}
.huge-iconuseroutlineuser2 {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5rem;
}
.button7 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  /* font-family: Lexend; */
  color: #4b4ad5;
  text-align: center;
}
.huge-iconarrowsoutlinedirec10 {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5rem;
}
.button6,
.button0,
.f-r-a-m-e-input-field-frame-la {
  flex: 1;
  border-radius: 10px;
  flex-direction: row;
  align-items: flex-start;
}
.f-r-a-m-e-input-field-frame-la {
  display: flex;
  justify-content: flex-start;
  gap: 0 0.5rem;
}
.button0 {
  cursor: pointer;
  background-color: #4b4ad5;
  border: 1px solid #4b4ad5;
  color: rgb(255, 255, 255) !important;
  padding: 0.7rem 0;
  /* margin-left: 1.5rem; */
  margin-right: 8px;
  z-index: 1;
}
.disabled-btn{
  cursor: pointer;
  background-color: #d0cfe3 !important;
  border: 1px solid #ccccdd !important;
  color: rgb(255, 255, 255) !important;
  padding: 0.7rem 0;
  margin-left: 1.5rem;
  margin-right: 8px;
  z-index: 1;
}
.button99{
  flex: 1 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  /* font-family: Lexend; */
  color: white;
  text-align: center;
}
.button6 {
  cursor: pointer;
  border: 1px solid #4b4ad5;
  padding: 0.75rem 1.188rem;
  background-color: transparent;
  z-index: 1;
}
.button-Green{
  cursor: pointer;
  background-color: #259557;
  border: 1px solid #259557;
  color: rgb(255, 255, 255) !important;
  padding: 0.7rem 0;
  margin-left: 1.5rem;
  margin-right: 8px;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  flex: 1 1;
  border-radius: 10px;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 55%;
}
.button-wrapper,
.button6,
.button0,
.group-div {
  display: flex;
  justify-content: flex-start;  
}
.button-wrapper {
  width: 81%;
  flex-direction: row;
  align-items: flex-start;
  /* padding: 0 0rem; */
  box-sizing: border-box;
}
.group-div {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.25rem 0 1.5rem;
  gap: 7.1rem;
  z-index: 1;
}
.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.pdf-icon {
  font-size: 48px;
  color: #007bff;
}

.pdf-label {
  margin-top: 5px;
  font-size: 14px;
}
@media screen and (max-width: 725px) {
  .group-div {
    gap: 8.438rem;
  }
}
@media screen and (max-width: 450px) {
  .group-div {
    gap: 4.25rem;
    padding-bottom: 1.25rem;
    box-sizing: border-box;
  }
}
