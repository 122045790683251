.standard-header-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;;
  height: 3.75rem;
}
/* .patients-table {
  position: fixed;
  top: 11.5%;
  left: 11%;
  width: 100%;
} */
.patients-table {
  position: absolute;
  top: 22%;
  left: 11%;
  width: 101.5%;
  height: 389px;
  overflow-y: auto;
}
.patinets-search-bar{
    position: absolute;
    top: 3rem;
    right: 69px;
    font-size: 0.9rem;
    letter-spacing: 0.1px;
    line-height: 2.25rem;
    font-weight: 500;
    font-family: inherit;
    color: #171725;
    width: 18.25rem;
    padding-left: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
}
.desktop-1-child {
  position: absolute;
  top: 25.188rem;
  left: 20.625rem;
  width: 6.25rem;
  height: 6.25rem;
  overflow: hidden;
  display: none;
}
.payment-number,
.payment-number1 {
  width: 1.375rem;
  position: relative;
  font-weight: 600;
}
.payment-number-parent {
  width: 6.288rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem;
}
.payment-number2 {
  position: relative;
  font-weight: 600;
}
.payment-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0.481rem 0 0;
}
.payment-number3 {
  position: relative;
  font-weight: 600;
}
.payment-number-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2.012rem 0 0;
}
.payment-number4 {
  position: relative;
  font-weight: 600;
}
.frame-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1.813rem 0 0;
}
.payment-number5 {
  position: relative;
  font-weight: 600;
}
.payment-number-wrapper1 {
  width: 7.731rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.payment-number6 {
  position: relative;
  font-weight: 600;
}
.payment-number-frame {
  position: absolute;
  height: 4.71%;
  /* width: 84.03%; */
  /* top: 15.23%; */
  /* right: 4.86%; */
  top: 16%;
  left: 11.11%;
  border-radius: 10px;
  background-color: #f1f1f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  /* gap: 0 5.4rem; */
  width: 84%;
}
.products-list {
  margin: 0;
  position: absolute;
  top: 3rem;
  left: 10rem;
  font-size: 1.5rem;
  letter-spacing: 0.1px;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: inherit;
  color: #171725;
  display: inline-block;
  width: 14.25rem;
  height: 2rem;
}
.desktop-1-item {
  position: absolute;
  top: 2.875rem;
  left: 10.625rem;
  width: 6.25rem;
  height: 5.563rem;
  overflow: hidden;
  display: none;
}
.desktop-1 {
  /* border-top: 1px solid lightgray; */
  width: 100%;
  height: 100%;
  /* position: relative; */
  background-color: #fafafb;
  overflow: hidden;
  letter-spacing: normal;
  text-align: left;
  font-size: 1rem;
  color: #454551;
  font-family: Poppins;
}
.chat-app{
  position: absolute;
  height: 53.5%;
  top: 0%;
  left: 110px;
  border-radius: 10px;
  background-color: #f1f1f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  /* gap: 0 5.4rem; */
  width: 92.5%;
}
@media screen and (max-width: 925px) {
  .desktop-1 {
    height: auto;
    min-height: 1024;
  }
}
@media screen and (max-width: 450px) {
  .products-list {
    font-size: 1.188rem;
    line-height: 1.813rem;
  }
}
