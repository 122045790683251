
.loading {
    display: flex;
    align-items: center;
    justify-content:center;
    height: 100%;
  }
.loaderAnimation{
    width: 150px;
}
.loading_with_bg {
    @extend .loading;
    background-color: #f1f3f6;
}