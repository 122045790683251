.custom-header-card .ant-card-head {
    padding: 0px 8px;
    font-size: 14px;
    color: #760fb2;
    font-weight: 700;
    min-height: 40px;
    max-height: 40px;
}

.custom-header-card .ant-card-body {
    padding: 12px;
    min-height: 40px;
    max-height: 40px;
}

.custom-tabs .ant-tabs-tab {
    width: 140px;
    text-align: center;
}

:hover.ant-tabs-tab {
    color: #760fb2 !important;
}

.custom-tabs .ant-tabs-tab-active {
    background-color: #ebdbf4 !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #760fb2 !important;
}

:where(.css-amq5gd).ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
:where(.css-amq5gd).ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    color: #760fb2 !important;
}

.custom-tabs .ant-tabs-tab-btn {
    width: 100%;
}

.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    transition: background-color 0.3s ease;
}

.meal-data-container {
    width: 95%;
    margin-left: 10px;

    .meal-data-header {
        display: flex;
        height: 40px;
        gap: 60px;
        align-items: center;

        .meal-header-title {
            font-size: 16px;
            font-weight: 500;
        }

        .meal-header-block {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            align-items: center;
        }
    }

    .option-header {
        height: 40px;
        border: 1px solid #760fb2;
        margin-top: 10px;
        padding: 1px;

        .option-tab .ant-tabs-tab {
            width: 140px;
            text-align: center;
            border-radius: 0px !important;
        }

        :hover.ant-tabs-tab {
            color: #760fb2 !important;
        }

        .option-tab .ant-tabs-tab-active {
            background-color: transparent !important;
            border-width: 0px 0px 3px 0px !important;
            border-color: #760fb2 !important;
        }

        :where(.css-dev-only-do-not-override-amq5gd).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #760fb2 !important;
        }

        .option-tab .ant-tabs-tab-btn {
            width: 100%;
        }

        .option-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
            transition: none !important;
        }
    }
}

.option-containers {
    width: 95% !important;
    border: none !important;
    padding: 0px !important;
    margin: 0px 0px 0px 3% !important;

    .tips-container {
        display: flex;
        gap: 10px;
        align-items: center;

        .tips-title {
            font-size: 16px;
        }
    }

    .total-container {
        width: 95%;
        margin-left: 3%;
        margin-top: 20px;

        .custom-total-card .ant-card-head {
            padding: 0px !important;
            font-size: 16px;
            color: #760fb2;
            font-weight: 700;
            min-height: 40px;
            max-height: 40px;
            background-color: #ebdbf4;
            text-align: center;
        }

        .custom-total-card .ant-card-body {
            padding: 2px !important;
            min-height: 40px;
            max-height: 40px;
            background-color: #ebdbf4;
            text-align: center;
            font-weight: 500;
            font-size: 15px;
        }
    }

    .food-items-container {
        padding: 10px !important;
        border: 1px solid #760fb2;
        width: 90%;
        margin-left: 4%;

        .custom-food-itme-card .ant-card-head {
            padding: 0px 8px !important;
            font-size: 14px;
            color: #760fb2;
            font-weight: 600;
            min-height: 40px;
            max-height: 40px;
        }

        .custom-food-itme-card .ant-card-body {
            padding: 8px !important;
            min-height: 40px;
            max-height: 40px;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.custom_label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: .7rem;
    line-height: 20px;
    color: #760fb2;
    margin-top: 0;
    padding: 0;
}

.nutrient_inputs {
    border: 1px solid #cecece;
    width: 85%;
    padding: 10px;
    border-radius: 16px;

    .search_bar {
        width: 85% !important;
    }

    .ant-card {
        width: 100% !important;
        border: none !important;
    }

    .ant-card-head {
        // color: rgb(118, 15, 178);
        border: none !important;
        // border-radius: 3px !important;
    }

    .ant-card-body {
        color: rgb(118, 15, 178);
        border: 1px solid #cecece !important;
        border-radius: 3px !important;
    }
}

.data-list {
    width: 90%;

    .ant-card-body {
        border: 1px solid #cecece !important;
        border-radius: 3px !important;
        color: rgb(118, 15, 178)
    }
}