.exercise-assessment-form-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    padding: 20px;
    min-height: 100vh;
    background-color: white;
}

.back-icon {
    cursor: pointer;
    height: 20px;
    width: auto;
}

.exercise-assessment-form-heading {
    background-color: #EBDBF4;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    width: 100%;
    padding: 15px 0;
    color: #8129B9
}

.assessment-upper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 15px;
    font-weight: 500;
}

.exercise-assessment-tabs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    margin-top: 20px;

    .exercise-assessment-tab {
        display: flex;
        width: 33%;
        justify-content: center;
        padding: 10px 0;
        font-weight: 600;
        cursor: pointer;
    }

    .exercise-assessment-tab-active {
        display: flex;
        width: 33%;
        justify-content: center;
        padding: 10px 0;
        font-weight: 600;
        cursor: pointer;
        color: #8129B9;
        border-bottom: 2px solid #8129B9;
    }

}

.exercise-assessment-personal {
    display: flex;
    flex-direction: column;
    //margin-top: 40px;
    text-align: center;
    align-items: center;
    width: 100%;

    .assessment-personal-heading {
        background-color: #EBDBF4;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        width: 100%;
        padding: 10px;
        color: #8129B9
    }

    .personal-que-ans {
        display: flex;
        flex-direction: column;
        width: 100%;

        .personal-que-ans-inner {
            display: flex;
            // align-items: center;

            .personal-que-ans-left {
                width: 300px;
                display: flex;
                justify-content: space-between;
                font-size: 15px;
                font-weight: 600;
                color: #8129B9;
            }

            input {
                border: none;
                padding: 5px;
                width: 400px;
                margin-left: 15px;
                border-bottom: 1px solid lightgrey;
                height: 20px;
                font-size: 15px;
                color: black
            }
        }
    }


}

.exercise-assessment-other {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    gap: 20px;

    .assessment-other-heading {
        background-color: #EBDBF4;
        font-size: 20px;
        font-weight: 600;
        padding: 20px 5px;
        color: #8129B9;
        text-align: center;
    }

    .assessment-que-ans {
        display: flex;
        flex-direction: column;
        width: 100%;

        .assessment-que {
            font-size: 15px;
            font-weight: 600;
            color: #8129B9;
        }

        textarea {
            border: 1px solid lightgray;
            outline: none;
            min-height: 50px;
            max-height: 50px;
            max-width: 100%;
            width: 100%;
            margin-top: 8px;
            resize: none;
        }
    }

    .assessment-target-que-ans {
        display: flex;
        flex-direction: row;
        align-items: center;

        .assessment-que {
            font-size: 15px;
            font-weight: 600;
            color: #8129B9;
            width: 120px;
        }

        .assessment-target {
            width: 150px;
        }

        .assessment-target-limit {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 20px;
            gap: 8px;

            .limit {
                width: 60px;
            }
        }
    }
}

.add-que-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    textarea {
        border: 1px solid lightgray;
        outline: none;
        min-height: 50px;
        max-height: 50px;
        max-width: 100%;
        width: 100%;
        margin-top: 8px;
        resize: none;
    }
}

.add-que-button {
    border: 1px solid #760fb2;
    padding: 6px 8px;
    border-radius: 4px;
    color: #760fb2;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    height: 40px;
    font-size: 15px;
    margin-top: 20px;
}

.save-que-button {
    background: #760fb2;
    padding: 8px 10px;
    border-radius: 4px;
    color: #fff;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 40px;
    font-size: 15px;
    margin-top: 15px;
}

.save-que-button-disable {
    background: #760fb2;
    padding: 8px 10px;
    border-radius: 4px;
    color: #fff;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: no-drop;
    border: none;
    height: 40px;
    font-size: 15px;
    margin-top: 15px;
    opacity: 0.5;
}

.data-points-table-main {
    min-width: 100%;
    overflow: auto;
    margin-top: 20px;

    .data-points-table {
        min-width: 100%;
        border-collapse: collapse;

        .data-points-table-header-row {
            background-color: #f0e7f7;

            .data-points-table-header-data {
                padding: 10px 10px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                min-width: 200px;
                align-items: center;
                justify-content: center;
            }

            .fixed-column {
                position: sticky;
                left: 0;
                z-index: 1;
                background-color: #f0e7f7;
            }
        }

        .fixed-column {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: white;
        }

        .data-points-table-body-data {
            //vertical-align: text-top;
            padding: 10px 10px;
            font-size: 14px;
            text-align: center;
            border-bottom: 1px solid #f1f1fc;
            align-items: center;
            justify-content: center;
            min-width: 200px;

            :where(.css-dev-only-do-not-override-amq5gd).ant-picker input {
                border: none
            }

            input {
                //border: none;
                padding: 5px;
                // width: 400px;
                //border-bottom: 1px solid lightgrey;
                border: 1px solid lightgray;
                height: 20px;
                font-size: 15px;
                color: black
            }
        }
    }
}

.health-goals-table-main {
    min-width: 100%;
    overflow: auto;
    margin-top: 20px;

    .health-goals-table {
        min-width: 100%;
        border-collapse: collapse;
        overflow-x: auto;

        .health-goals-table th,
        .health-goals-table td {
            padding: 8px;
        }

        .health-goals-table-header-row {
            background-color: #f0e7f7;

            .health-goals-table-header-data {
                padding: 10px 10px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                min-width: 200px;
                align-items: center;
                justify-content: center;
            }

            .fixed-column {
                position: sticky;
                left: 0;
                z-index: 1;
                background-color: #f0e7f7;
            }
        }

        .fixed-column {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: white;
        }

        .health-goals-table-body-data {
            //vertical-align: text-top;
            padding: 10px 10px;
            font-size: 14px;
            text-align: center;
            border-bottom: 1px solid #f1f1fc;
            align-items: center;
            justify-content: center;
            min-width: 200px;

            :where(.css-dev-only-do-not-override-amq5gd).ant-picker input {
                border: none
            }

            input {
                //border: none;
                padding: 5px;
                // width: 400px;
                //border-bottom: 1px solid lightgrey;
                height: 20px;
                border: 1px solid lightgray;
                font-size: 15px;
                color: black
            }
        }
    }
}


.assessment-type {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-bottom: 20px;
    gap: 5px;
    margin-top: 20px;
}

.assessment-name-container {
    display: flex;
    gap: 8px;
    align-items: center;

    input {
        // border: none;
        padding: 5px;
        width: 400px;
        margin-left: 15px;
        height: 20px;
        font-size: 15px;
        color: black
    }
}

.rmsc .dropdown-content {
    display: flex !important;
}

.rmsc .dropdown-content .options {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
}

.rmsc .dropdown-content .options .select-item .item-renderer {
    display: flex !important;
    align-items: center !important;
    background-color: red !important;
    font-size: 15px;
    justify-content: flex-start !important;
    /* Use 'justify-content' to align flex items horizontally */
}

/* Adjust the individual elements if needed */
.rmsc .dropdown-content .options .select-item .item-renderer .checkbox {
    margin-right: 5px;
    /* Example: Adding margin between checkbox and text */
}

.rmsc .dropdown-content .options .select-item .item-renderer span {
    /* Additional styles for text content */
    text-align: left;
}

.exercise-assessment-form-container {
    :where(.css-dev-only-do-not-override-amq5gd).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 250px !important;
    }
}