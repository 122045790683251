.exercise-assessment-view-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    gap: 20px;
    margin: 20px 0;

    .exercise-assessment-view-inner {
        display: flex;
        flex-direction: column;
        padding: 8px;
        width: 94%;
        margin-left: 20px;

        .exercise-assessment-heading {
            font-size: 22px;
            font-weight: 700;
            color: #8129B9;
            width: 100%;
        }
    }

    .exercise-assessment-view-tabs {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
        margin-top: 10px;

        .exercise-assessment-view-tab {
            display: flex;
            width: 33%;
            justify-content: center;
            padding: 10px 0;
            font-weight: 600;
            cursor: pointer;
        }

        .exercise-assessment-tab-view-active {
            display: flex;
            width: 33%;
            justify-content: center;
            padding: 10px 0;
            font-weight: 600;
            cursor: pointer;
            color: #8129B9;
            border-bottom: 2px solid #8129B9;
        }

    }

    .exercise-assessment-view-inner {
        display: flex;
        flex-direction: column;
        padding: 8px;
        width: 94%;
        //box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
        margin-left: 20px;

        .exercise-assessment-heading {
            font-size: 22px;
            font-weight: 700;
            color: #8129B9;
            width: 100%;
        }

        .exercise-assessment-view-personal {
            display: flex;
            flex-direction: column;
            width: 100%;

            .exercise-assessment-view-personal-heading {
                background-color: #EBDBF4;
                font-size: 18px;
                text-align: center;
                font-weight: 600;
                padding: 10px;
                color: #8129B9
            }

            .exercise-personal-view-que-ans {
                display: flex;
                flex-direction: column;
                width: 100%;

                .exercise-personal-view-que-ans-inner {
                    display: flex;
                    // align-items: center;

                    .exercise-personal-view-que-ans-left {
                        width: 200px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 15px;
                        font-weight: 600;
                        color: #8129B9;
                        // align-items: center;
                    }

                    .exercise-personal-view-que-ans-right {
                        display: flex;
                        font-size: 14px;
                        font-weight: 600;
                        align-items: center;
                        margin-left: 20px;
                        // text-align: center;
                        width: 600px
                    }
                }
            }
        }

        .exercise-assessment-view-other {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 30px;
            gap: 20px;

            .exercise-assessment-view-other-heading {
                background-color: #EBDBF4;
                font-size: 18px;
                font-weight: 600;
                padding: 20px 5px;
                color: #8129B9;
                text-align: center;
            }

            .exercise-assessment-view-que-ans {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 5px;
                margin-top: 10px;

                .exercise-assessment-view-que {
                    font-size: 15px;
                    font-weight: 600;
                    color: #8129B9;
                }

                .exercise-assessment-view-ans {
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 20px;
                }

            }

            .exercise-assessment-view-target-que-ans {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 5px;
                margin-top: 10px;

                .exercise-assessment-view-que {
                    font-size: 15px;
                    font-weight: 600;
                    width: 100px;
                    color: #8129B9;
                }

                .exercise-assessment-view-ans {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 20px;

                    .title {
                        display: flex;
                        flex-direction: row;
                        gap: 3px;
                        width: 60px;
                    }

                    .value {
                        display: flex;
                        flex-direction: row;
                        gap: 2px;
                        width: 60px;
                    }
                }

            }
        }
    }

    .data-points-table-main-view {
        min-width: 100%;
        overflow: auto;
        margin-top: 20px;

        .data-points-table-view {
            min-width: 100%;
            border-collapse: collapse;

            .data-points-table-header-row-view {
                background-color: #f0e7f7;

                .data-points-table-header-data-view {
                    padding: 10px 10px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    min-width: 200px;
                    align-items: center;
                    justify-content: center;
                }
            }

            .data-points-table-body-data-view {
                //vertical-align: text-top;
                padding: 10px 10px;
                font-size: 14px;
                text-align: center;
                border-bottom: 1px solid #f1f1fc;
                align-items: center;
                justify-content: center;
                min-width: 100px;

                input {
                    //border: none;
                    padding: 5px;
                    // width: 400px;
                    //border-bottom: 1px solid lightgrey;
                    border: 1px solid lightgray;
                    height: 20px;
                    font-size: 15px;
                    color: black
                }
            }
        }
    }

    .health-goals-table-main-view {
        min-width: 100%;
        overflow: auto;
        margin-top: 20px;

        .health-goals-table-view {
            min-width: 100%;
            border-collapse: collapse;

            .health-goals-table-header-row-view {
                background-color: #f0e7f7;

                .health-goals-table-header-data-view {
                    padding: 10px 10px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    min-width: 200px;
                    align-items: center;
                    justify-content: center;
                }
            }

            .health-goals-table-body-data-view {
                //vertical-align: text-top;
                padding: 10px 10px;
                font-size: 14px;
                text-align: center;
                border-bottom: 1px solid #f1f1fc;
                align-items: center;
                justify-content: center;
                min-width: 200px;

                input {
                    //border: none;
                    padding: 5px;
                    // width: 400px;
                    //border-bottom: 1px solid lightgrey;
                    height: 20px;
                    border: 1px solid lightgray;
                    font-size: 15px;
                    color: black
                }
            }
        }
    }
}