.patient-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
  
    .content-wrapper {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
  
    .main-content {
      flex: 1;
      padding: 24px;
      background-color: #fafafa;
      overflow-y: auto;
    }
  
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
  
    .breadcrumb {
      span {
        color: #666;
        cursor: pointer;
  
        &.current {
          color: #333;
          font-weight: 500;
        }
  
        &.separator {
          margin: 0 8px;
        }
      }
    }
  
    .search-filter {
        display: flex;
        gap: 12px;
      
        .search-input {
          padding: 12px 16px;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          min-width: 300px;
          font-size: 15px;
          transition: all 0.2s ease;
          background-color: #f8f8f8;
          
          &:focus {
            outline: none;
            border-color: #760FB2;
            box-shadow: 0 0 0 2px rgba(118, 15, 178, 0.1);
            background-color: white;
          }
      
          &::placeholder {
            color: #888;
          }
        }
      }
  
      .view-selector {
        display: flex;
        gap: 12px;
        background: white;
        padding: 12px 24px;
        border-radius: 8px;
        height: auto;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        margin-bottom: 24px;
      
        .view-btn {
          padding: 12px 24px;
          border: none;
          background: #f5f5f5;
          cursor: pointer;
          font-size: 15px;
          border-radius: 6px;
          margin: 0;
          flex: 1;
          transition: all 0.2s ease;
          color: #555;
          font-weight: 500;
          
          &:hover {
            background: #eee;
            color: #760FB2;
          }
      
          &.active {
            background: #760FB2;
            color: white;
            font-weight: 600;
            box-shadow: 0 2px 4px rgba(118, 15, 178, 0.2);
            
            &:hover {
              background: #6a0ea0;
            }
          }
        }
      }
  }