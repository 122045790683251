.patient-report-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 20px;

    .report-header {
        display: flex;
        height: 45px;
        justify-content: space-between;
        background: linear-gradient(90deg, #4B4AD5 0%, #A461D8 100%);
        align-items: center;
        padding: 0 10px;
    }

    .report-title {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        /* 137.5% */
        letter-spacing: 0.1px;
    }

    .patient-details-report {
        display: flex;
        background-color: #F6F6FD;
        padding: 0 10px;
        flex-direction: column;

        .patient-details-first-row {
            display: flex;
            margin: 21px auto 0px auto;
            justify-content: space-between;
            align-items: center;
            width: 97.5%;

            .patient-profile-sec {
                display: flex;
                gap: 13px;
                align-items: center;

                .patient-profile-pic {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .patient-name-age-container {
                    display: flex;
                    flex-direction: column;

                }
            }

            .hc-profile-sec {
                display: flex;
                gap: 13px;

                .hc-profile-name {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .patient-details-second-row {
            display: flex;
            width: 97.5%;
            justify-content: space-between;
            margin: 15px auto;

            .patient-details-second {
                display: flex;
                width: 25%;
                flex-direction: column;
            }
        }

        .hc-month-content {
            display: flex;
            width: 98%;
            margin: 20px auto;
            background-color: white;
            border-radius: 10px;
        }
    }

    .reports-all-tables {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 0 10px;
        margin-top: 20px;

        .table-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 99%;

            .table-heading {
                display: flex;
                gap: 10px;
                align-items: center;
            }

            .table-header {
                background-color: #F1F1F5;
                border-radius: 5px;
                width: 98%;
                display: flex;
                padding: 3px 8px;
            }

            .table-data {
                width: 97%;
                display: flex;
                padding: 0 8px;
                border-bottom: 1px solid #E2E2EA;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: space-between;
            }

            .heading-content {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .title {
                    font-weight: 600;
                    font-size: 12px;
                }

                .adherence-status {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .status-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 10px;
                        gap: 2px;
                        font-weight: 500;

                        .status-icon {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;

                            &.good {
                                background-color: #C6E0B4;
                            }

                            &.needs-improvement {
                                background-color: #F8CBAD;
                            }
                        }
                    }
                }
            }

            .box-container {
                height: fit-content;
                width: 95%;
                display: flex;
                flex-direction: column;

                .box-rows {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 20px;

                    .box {
                        height: 110px;
                        width: 30%;
                        text-align: center;
                        font-size: 11px;

                        .sub-box-1 {
                            height: 33%;
                            border: 1px solid black;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            background-color: #9AC2E6;
                            align-content: center;
                            font-weight: 500;
                        }

                        .sub-box-2 {
                            height: 33%;
                            border-left: 1px solid black;
                            border-right: 1px solid black;
                            background-color: #F8CBAD;
                            align-content: center;
                            font-weight: 600;
                            font-size: 12px;
                        }

                        .sub-box-3 {
                            height: 33%;
                            border: 1px solid black;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                            background-color: #E3EFDA;
                            align-content: center;
                            padding: 0px 5px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .second-table-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;
            width: 99%;

            .second-table-header {
                background-color: #F1F1F5;
                border-radius: 5px;
                width: 98%;
                display: flex;
                padding: 4px 8px;
                justify-content: space-between;

                .second-table-header-left {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }

                .second-table-header-right {
                    display: flex;
                }
            }

            .second-table-data {
                width: 97%;
                display: flex;
                padding: 0 8px;
                border-bottom: 1px solid #E2E2EA;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: space-between;
            }
        }
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker .ant-picker-input>input {
        font-size: 8px;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker-focused.ant-picker {
        width: 48%;
    }

    :where(.css-dev-only-do-not-override-amq5gd).ant-picker {
        width: 48%;
    }
}

.report-btn {
    background: #760fb2;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    height: 28px;
    margin: auto;
    margin-top: 20px;
    font-size: 11px;
}