.patients-icon {
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  z-index: 2;
}
.patients-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1.5rem 0 1.813rem;
}
.frame-child {
  width: 6.25rem;
  height: 6.25rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.menu {
  width: 5.25rem;
  position: relative;
  font-weight: 600;
  font-size: 0.9rem;
  display: inline-block;
}
.frame-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.56rem 0.25rem 0.56rem;
  margin-left: -0.5rem;
  /* gap: 0.313rem 0; */
  cursor: pointer;
}
.frame-parent2{
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.56rem 0.25rem 0.56rem;
  /* gap: 0.313rem 0; */
  /* position: absolute; */
  /* top: 5.75%; */
  /* margin-left: -0.5rem; */
  cursor: pointer;
}
.frame-parent3{
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.56rem 0.25rem 0.56rem;
  /* gap: 0.313rem 0; */
  /* position: absolute;
  top: 11.5%;
  margin-left: -0.5rem; */
  cursor: pointer;
}
.selector1 {
  width: 0.3rem;
  height: 5.863rem;
  position: absolute;
  border-radius: 0 100px 100px 0;
  background-color: #4b4ad5;
  z-index: 3;
  left: 0%;
  /* top: 6.8%;
  margin-top: -7.1rem; */
}
.selector2 {
  width: 0.3rem;
  height: 5.863rem;
  position: absolute;
  border-radius: 0 100px 100px 0;
  background-color: #4b4ad5;
  z-index: 3;
  left: 0%;
  /* top: 12.4%;
  margin-top: -7.1rem; */
}
.selector3 {
  width: 0.3rem;
  height: 5.863rem;
  position: absolute;
  border-radius: 0 100px 100px 0;
  background-color: #4b4ad5;
  z-index: 3;
  left: 0%;
  /* top: 17.8%;
  margin-top: -7.1rem; */
}
.main-side-nav-panel {
  /* position: absolute; */
  height: 100vh;
  width: 105px;
  top: 0;
  bottom: -79.1%;
  left: 0;
  background-color: #fff;
  box-shadow: -1px 0 0#e2e2ea inset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.188rem 0.563rem 104.438rem 0.063rem;
  box-sizing: border-box;
  text-align: center;
  font-size: 0.75rem;
  color: #454551;
  font-family: Poppins;
}
