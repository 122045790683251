.products-list10 {
  position: relative;
  font-size: 1rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  font-family: Poppins;
  color: #171725;
  text-align: left;
}
.edit-icon5 {
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
}
.frame-products-list-text-insta2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0.063rem;
}
.input-field12 {
  margin-left: -0.312rem;
  position: relative;
  font-size: 0.875rem;
  text-decoration: underline;
  font-weight: 500;
  font-family: Poppins;
  color: #454551;
  text-align: right;
  white-space: nowrap;
}
.input-field-frame-label-frame2,
.text6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field-frame-label-frame2 {
  cursor: pointer;
  align-items: flex-end;
  gap: 0 0.313rem;
}
.button-frame-button-label-fram,
.button-frame3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.button-frame-button-label-fram {
  flex: 1;
  justify-content: space-between;
  gap: 1.25rem;
}
.button-frame3 {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 1.25rem 0 1.5rem;
  box-sizing: border-box;
}
.button-frame-button-label-child {
  align-self: stretch;
  height: 0.063rem;
  position: relative;
  border-top: 1px solid #e2e2ea;
  box-sizing: border-box;
}
.placeholder-disable {
  width: 12rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #cbcbcb;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #949494;
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding: 1rem 2rem 1rem 1rem;
  gap: 0 0.5rem;
}
.label13 {
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.label-parent3,
.placeholder-label-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.label-parent3 {
  border-radius: 10px;
  gap: 0 0.25rem;
}
.placeholder-label-group {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 1.5rem;
  flex: 1;
  font-family: Lexend;
  font-weight: 300;
  font-size: 1rem;
  color: #100604;
  min-width: 9.313rem;
}
.button8 {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field13 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.label14 {
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.frame-child1,
.label-parent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.label-parent4 {
  border-radius: 10px;
  gap: 0 0.25rem;
}
.frame-child1 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 1.5rem;
  flex: 1;
  font-family: Lexend;
  font-weight: 300;
  font-size: 1rem;
  color: #100604;
  min-width: 9.313rem;
}
.frame-parent7 {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field14 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.products-list-input-field-grou2 {
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 2.5rem;
}
.button-frame-button-label,
.frame-products-list-input-fiel1,
.products-list-input-field-grou2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-products-list-input-fiel1 {
  width: 40.5rem;
  flex-direction: row;
  padding: 0 1.5rem;
  box-sizing: border-box;
}
.button-frame-button-label {
  align-self: stretch;
  flex-direction: column;
  gap: 1.25rem 0;
}
.label15 {
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.frame-label-frame {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.25rem;
}
.placeholder7 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.input-field-text {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field15 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.label16 {
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.label-parent5 {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.25rem;
}
.placeholder8 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: Lexend;
  color: #100604;
  text-align: left;
}
.huge-iconarrowsoutlinedirec19 {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5rem;
}
.frame-parent8 {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field16 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.products-list-input-field-grou3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.products-list-input-field-grou3 {
  width: 37.5rem;
  flex-wrap: wrap;
  gap: 0 0.5rem;
}
.hugeiconarrowsoutlinedirection2 {
  width: 37rem;
  padding: 0 0 0.375rem;
  box-sizing: border-box;
}
.button10 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  /* font-family: Lexend; */
  color: #fff;
  text-align: center;
}
.button9 {
  flex: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.button9 {
  cursor: pointer;
  border: 0;
  padding: 0.6rem 0.1rem;
  background-color: #4b4ad5;
}
.button-frame4,
.products-list-frame {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}
.button-frame4 {
  width: 14.125rem;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 2rem;
  /* position: absolute; */
  top: 91.5%;
  left: 31.5%;
}
.products-list-frame {
  margin: 0;
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.25rem 0;
  gap: 1.5rem 0;
  max-width: 100%;
}
.city-select{
  width: 15rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #868383;
  padding: 1rem 1rem;
  gap: 0 0.5rem;
}
@media screen and (max-width: 650px) {
  .products-list-input-field-grou2,
  .products-list-input-field-grou3 {
    gap: 0 1.25rem;
  }
}
@media screen and (max-width: 450px) {
  .button-frame-button-label-fram {
    flex-wrap: wrap;
  }
}
