.container-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-wrapper {
        height: 80px;
        width: 100%;
        justify-content: end;
        align-items: center;
        padding: 0px 5%;
        gap: 10px;

        .buttons {
            height: 40px;
            width: 200px;
            background-color: #760fb2;
            border: none;
            color: white;
            font-weight: 500;
            font-size: 16px;
            border-radius: 6px;
            cursor: pointer;
        }
    }

    .table-wrapper {
        width: 90vw;
    }

    .custom-table {
        .ant-table-thead>tr>th {
            background-color: #760fb2;
            color: white;
            border-radius: 0px !important;
            font-weight: 700;
        }

        .ant-pagination {

            .ant-pagination-item,
            .ant-pagination-item-active {
                border-color: #760fb2;

                a {
                    color: #760fb2;
                }
            }

            .ant-pagination-item-active {
                font-weight: bold;
            }
        }
    }
}

.custom-modal {
    .ant-modal {
        // height: 100vh;
    }

    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        height: 100%;

        .add-plan-wrapper {
            height: 95%;
            width: 100%;
            overflow: auto;

            .plan-details-wrapper {
                height: 200px;
                // background-color: gold;
                overflow: auto;
                border-bottom: solid lightgray 2px;
                padding-right: 10px;

                .row {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    padding: 2px 15px;

                    .column {
                        display: flex;
                        flex-direction: column;
                        width: 30%;

                        .label {
                            margin-left: 4px;
                            font-weight: 500;
                            font-size: 12px;
                            margin-top: 0px;
                            padding: 0px;
                            display: flex;
                            flex-direction: row;

                            .start {
                                color: red;
                                margin-left: 2px;
                            }
                        }

                        .error-text {
                            color: red;
                            font-size: 10px;
                        }

                        .date-picker,
                        .antd-input-type,
                        .time-picker,
                        .switch {
                            margin-top: 5px;
                        }

                        .antd-input-type {
                            color: black !important;
                        }

                        .antd-select-type {
                            width: 100% !important;
                        }

                        .ant-select-selection-item {
                            color: black !important;
                        }

                        .ant-select {
                            width: 100% !important;

                            .ant-select-selector {
                                width: 100% !important;
                            }
                        }
                    }

                    .switch-column {
                        display: flex;
                        flex-direction: column;
                        width: 30%;

                        .switch {
                            width: 10px;
                        }
                    }
                }
            }

            .exercise-details-wrapper {
                height: 62%;
                // background-color: aqua;
                overflow: auto;
                border-bottom: solid lightgray 2px;
                padding-right: 10px;
                padding-bottom: 10px;
                overflow: hidden;

                .header {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding: 0px 15px;
                    justify-content: space-between;
                    color: #760fb2;

                    .exercise {
                        font-size: 14px;
                        font-weight: 600;
                        width: 40%;
                        display: flex;
                        flex-direction: row;

                        .start {
                            color: red;
                            margin-left: 2px;
                        }
                    }

                    .prescribed {
                        font-size: 14px;
                        font-weight: 600;
                        width: 10%;
                        text-align: center;
                        display: flex;
                        flex-direction: row;

                        .exp {
                            font-size: 10px;
                            padding-top: 3px;
                            margin-left: 1px;
                        }

                        .start {
                            color: red;
                            margin-left: 2px;
                        }
                    }
                }

                .body {
                    height: 90%;
                    overflow: auto;
                    padding: 0px 12px;
                    // gap: 10px;
                    // background-color: aquamarine;

                    .exercise-list {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 10px;

                        .antd-exercise-input {
                            width: 40%;

                            .ant-select {
                                width: 100% !important;

                                .ant-select-selector {
                                    width: 100% !important;

                                    .ant-select-selection-item {
                                        color: black !important;
                                    }
                                }
                            }

                            .error-text {
                                color: red;
                                font-size: 10px;
                                margin-left: 5px;
                            }
                        }

                        .antd-prescribed-input {
                            width: 10%;

                            .antd-prescribed-input-type {
                                color: black !important;
                            }

                            .error-text {
                                color: red;
                                font-size: 10px;
                                margin-left: 5px;
                            }
                        }

                    }
                }
            }

            .buttons-wrapper {
                margin-top: 8px;
                height: 40px;
                // background-color: aquamarine;
                display: flex;
                justify-content: end;
                align-items: center;
                // padding: 0px 5%;
                gap: 10px;
                padding-right: 10px;

                .buttons {
                    height: 40px;
                    width: 120px;
                    background-color: #760fb2;
                    border: none;
                    color: white;
                    font-weight: 500;
                    font-size: 16px;
                    border-radius: 6px;
                    cursor: pointer;
                }
            }
        }

        ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        ::-webkit-scrollbar-track {
            background: white;
        }

        ::-webkit-scrollbar-thumb {
            background: #af93bf;
            border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #760fb2;
        }
    }
}

.view-plan-modal {
    .ant-modal {
        // height: 100vh;
    }

    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        height: 100%;

        .view-plan-wrapper {
            height: 90%;
            width: 100%;

            .body {
                height: 90%;
                display: flex;
                flex-direction: column;
                // overflow: auto;
                margin-top: 5px;

                .session-detail-wrapper {
                    height: 110px;
                    border-bottom: solid lightgrey 2px;
                    display: flex;
                    flex-direction: row;

                    .details {
                        width: 50%;

                        .row {
                            display: flex;
                            flex-direction: row;
                            margin-left: 10px;
                            height: 30px;

                            .key {
                                width: 50%;
                                font-size: 14px;
                                font-weight: 600;
                                color: #760fb2;
                            }

                            .value {
                                width: 50%;
                            }
                        }
                    }

                    .left-detail {
                        @extend .details;
                    }

                    .right-detail {
                        @extend .details;
                    }
                }

                .plan-detail-wrapper {
                    overflow: auto;
                    height: 100%;
                    padding-right: 10px;

                    .plan-detail-header {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0px 15px;
                        justify-content: space-between;
                        color: #760fb2;
                        border-bottom: solid rgb(243, 243, 243) 2px;

                        .exercise-image {
                            width: 15%;
                            font-size: 14px;
                            font-weight: 600;
                        }

                        .exercise-name {
                            font-size: 14px;
                            font-weight: 600;
                            width: 25%;
                            display: flex;
                            flex-direction: row;
                        }

                        .count {
                            font-size: 14px;
                            font-weight: 600;
                            width: 15%;
                            text-align: center;
                            display: flex;
                            flex-direction: row;
                        }
                    }

                    .plan-detail-body {
                        overflow: auto;
                        padding: 0px 12px;
                        margin-top: 10px;

                        .plan-detail-list {
                            overflow: auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            .image-box {
                                width: 15%;
                                margin-left: 5px;

                                img {
                                    height: 70px;
                                    object-fit: cover;
                                    border-radius: 10px;
                                }
                            }

                            .exercise-box {
                                font-size: 14px;
                                font-weight: 600;
                                width: 25%;
                                display: flex;
                                flex-direction: row;
                            }

                            .count-box {
                                width: 15%;
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }

                        :hover {
                            background-color: rgb(242, 242, 242);
                        }
                    }

                }
            }

            ::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            ::-webkit-scrollbar-track {
                background: white;
            }

            ::-webkit-scrollbar-thumb {
                background: #af93bf;
                border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #760fb2;
            }
        }
    }
}

.view-qna-modal {
    .ant-modal {
        // height: 100vh;
    }

    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        height: 100%;

        .view-qna-wrapper {
            height: 90%;
            width: 100%;
            overflow: auto;

            .no-data {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;
            }

            .wrapper-header {
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0px 15px;
                justify-content: space-between;
                color: #760fb2;
                border-bottom: solid rgb(243, 243, 243) 2px;

                .question {
                    font-size: 14px;
                    font-weight: 600;
                    width: 40%;
                    display: flex;
                    flex-direction: row;
                }

                .answer {
                    font-size: 14px;
                    font-weight: 600;
                    width: 20%;
                    text-align: center;
                    display: flex;
                    flex-direction: row;
                }
            }

            .wrapper-body {
                height: 90%;
                overflow: auto;
                padding: 0px 12px;

                .group {
                    margin-top: 10px;

                    .group-heading {
                        font-size: 14px;
                        font-weight: 800;
                        color: #760fb2;
                    }

                    .question-list {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        height: 35px;
                        align-items: center;
                        border-radius: 5px;
                        border-bottom: solid rgb(243, 243, 243) 2px;

                        .question-box {
                            font-size: 14px;
                            font-weight: 600;
                            width: 40%;
                            margin-left: 5px;
                        }

                        .answer-box {
                            width: 20%;
                            font-size: 14px;
                            font-weight: 500;
                        }

                    }

                    :hover {
                        background-color: rgb(242, 242, 242);
                    }
                }

            }
        }
    }
}

.view-assessment-score-modal {
    .ant-modal {
        // height: 100vh;
    }

    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    color: #760fb2;
                    // color: white;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}

.view-plan-score-modal {
    .ant-modal {
        // height: 100vh;
    }

    .ant-modal-header {
        padding-bottom: 10px;
        border-bottom: solid lightgray 2px;
    }

    .ant-modal-content {
        height: 85vh;
    }

    .ant-modal-body {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;

        .view-assessment-wrapper {
            .custom-card .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                // color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card-h .ant-card-head {
                padding: 0px 8px;
                font-size: 14px;
                color: #760fb2;
                font-weight: 700;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-card-h .ant-card-body {
                padding: 12px;
                min-height: 40px;
                max-height: 40px;
            }

            .custom-table {
                height: fit-content;
                overflow: auto;

                .ant-table-thead>tr>th {
                    // color: #760fb2;
                    // color: white;
                    border-radius: 0px !important;
                    font-weight: 700;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    ::-webkit-scrollbar-thumb {
        background: #af93bf;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #760fb2;
    }
}