.diet-main-component{
    padding: 16px;
   // width: 97%;
   
}
.diet-component{

}
.diet-grid-component{
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr 1fr 1fr;
    margin-top: 20px;
    // overflow-x: auto;
    // width: 30%;
    // box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    // margin-left: auto;
    // margin-right: auto;
}

.page-header{
    display: flex;
    justify-content: space-between;
}

.next-btn{
    background: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    color: #760fb2;
    width: 80px;
    border: 1px solid #760fb2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 20px;
    font-size: 13px;
    margin-top: 15px;
}

.add-diet-assessment-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    // background-color: red;
}

.generate-report-btn {
    background: white;
    border: none;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #760fb2;
    cursor: pointer;
    color: #760fb2;
    font-size: 10px;
    font-weight: 600;
}
.generate-report-btn:hover {
    background-color: #ebdbf4;
    color: #760fb2;
}