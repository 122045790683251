.table-container-custom {
  position: relative;
  padding: 0 !important;
  width: 100%;
  overflow-x: auto;
  font-size: 12px;

  .table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #f0f0f0;

    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #f0f0f0;
    }

    th {
      background-color: #e9dcf5;
      font-weight: 500;
    }

    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tbody tr:hover {
      background-color: #faf6fe;
    }

    .no-data {
      text-align: center;
      color: #777;
      font-size: 14px;
      // font-style: italic;
      // font-weight: 600;
    }
  }

  .pagination {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    padding: 16px;
    .records-selection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      gap: 10px;
      .records-text {
        width: 250px;
      }
    }
    .btn-container {
      display: flex;
      align-items: center;
      gap: 10px;
      .button {
        width: 50px;
        height: 30px;
      }
      .btn-page-number {
        width: 30px !important;
        height: 30px;
      }
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000; /* Ensure it's on top of the table */
  }
}
