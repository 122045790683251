.patient-details-card-container {
  position: relative;
  //   width: 100%;
  padding: 16px;
  .patient-avatar-and-info {
    display: flex;
    align-items: center;
    gap: 0;
    width: 100%;
    .patient-info {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0;
      color: #333;
      margin-left: 10px;
      margin-top: 0;
      .patient-name {
        font-size: 24px;
        font-weight: 600;
        margin-right: 10px;
      }
      .minus-symbol {
        color: #777;
        margin-left: 5px;
        margin-right: 5px;
      }
      .patient-gender,
      .patient-age {
        color: #777;
      }
    }
  }
  .features-wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-top: 8px;
    .feature {
      display: flex;
      flex-direction: column;
      // gap: 5px;
      .feature-label {
        font-size: 12px;
        color: #777;
      }
      .feature-value {
        font-size: 18px;
        color: #333;
        font-weight: 600;
      }
    }
    .feature-service {
      background-color: #efefef;
      padding: 10px 16px;
      border-radius: 16px;
      .feature-services-value {
        display: flex;
        align-items: center;
        // padding-right: 16px;
        font-size: 14px;
        gap: 8px;
      }
    }
  }
  .checked {
    color: green;
  }
  .unchecked {
    color: red;
  }
  .action-button-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
    // width: auto;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
