.patient-list-component {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.page-header {
    display: flex;
    align-items: center;
    gap: 20px;

    .header-text {
        font-size: 20px;
        font-weight: 600;
    }

    .back-icon {
        cursor: pointer;
        height: 20px;
        width: auto;
    }
}

.table {
    min-width: 100%;
    border-collapse: collapse;
}

.table-header-row {
    background-color: #f0e7f7;
}

.table-header-data {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.table-body-data {
    vertical-align: text-top;
    padding: 15px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #f1f1fc;
    align-items: center;
    justify-content: center;
}

.patient-name-container {
    display: flex;
    flex-direction: column;

    .patient-name {
        color: #760fb2;
        font-weight: 600;
        cursor: pointer;
    }

    .patient-age-gender {
        font-size: 12px;
        color: #9b9b9b;
    }
}

.adherence-details-container {
    display: flex;
    flex-direction: column;
}

.take-assessment-btn {
    border: 1px solid #760fb2;
    padding: 6px 8px;
    border-radius: 4px;
    color: #760fb2;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;
    height: 40px;
    font-size: 15px;
    margin-top: 20px;
}

.patient-tab{
    width: 100%;
    background-color: #f1f1fc;
    height:40px;
   // border-radius: 10px;
    display: flex;
    align-items: center;

    .tab{
        width: 25%;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #760fb2;
        cursor: pointer;
    }

    .active-tab{
        width: 25%;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: white;
        background-color: #760fb2;
        height:100%;
        align-items: center;
        display: flex;
        justify-content: center;
        // border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
        cursor: pointer;
    }

    // .active-tab-2{
    //     width: 25%;
    //     text-align: center;
    //     font-size: 18px;
    //     font-weight: 600;
    //     color: white;
    //     background-color: #760fb2;
    //     height:100%;
    //     align-items: center;
    //     display: flex;
    //     justify-content: center;
    //     border-top-right-radius: 10px;
    //     border-bottom-right-radius: 10px;
    //     cursor: pointer;
    // }
}