.change-diet-btn {
    background: white;
    border: none;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #760fb2;
    cursor: pointer;
    color: #760fb2;
    font-size: 10px;
    font-weight: 600;
}
.change-diet-btn:hover {
    background-color: #ebdbf4;
    color: #760fb2;
}