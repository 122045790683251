.summary-header {
  width: 86.75rem;
  align-items: flex-start;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 1.25rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.5rem;
  color: #171725;
  font-family: Poppins;
}
.sub-header {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.patient-info {
  display: flex;
  align-content: center;
  z-index: 1;
  gap: 1rem;
  font-size: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
  width: 39%;
}
.patient-info-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.patient-info-details {
  display: flex;
  align-content: space-between;
  gap: 4rem;
}
.patientInfo-label {
  font-size: 1.3rem;
  color: #000000;
}
.view-details-btn {
  padding-top: 5px;
}
.view-details-btn-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e0d8d8;
  color: #4b4ad5;
  border-radius: 8px;
  cursor: pointer;
}
.patient-summary {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fafafb;
  overflow: hidden;
  flex-direction: column;
  padding: 2.438rem 1.875rem 4.375rem;
  box-sizing: border-box;
  gap: 2.063rem 0;
  letter-spacing: normal;
}
.cycle-table-frame {
  /* position: absolute; */
  /* height: 9.71%; */
  /* width: 84.03%; */
  /* top: 15.23%; */
  /* right: 4.86%; */
  /* border-radius: 10px; */
  background-color: #ffffff;
  display: flex;
  margin-left: 6rem;
  /* flex-direction: row; */
  /* align-items: center; */
  padding: 6px 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  /* gap: 0 5.4rem; */
  width: 99%;
}
.cycletableframe {
  /* position: absolute; */
  height: 8%;
  width: 84.03%;
  /* top: 48%; */
  border-radius: 10px;
  background-color: #f1f1f5;
  display: flex;
  margin-left: 6rem;
  /* flex-direction: row; */
  /* align-items: center; */
  padding: 1rem 1rem;
  box-sizing: border-box;
  justify-content: space-between;
  /* margin-top: -15%; */
  /* gap: 0 5.4rem; */
  width: 89%;
}
.cycle-title {
  margin: 0;
  position: absolute;
  top: 14rem;
  left: 6rem;
  font-size: 1.5rem;
  letter-spacing: 0.1px;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: inherit;
  color: #171725;
  display: inline-block;
  /* width: 14.25rem; */
  /* height: 2rem; */
}
.cycles-table {
  width: 90%;
  margin-top: 0%;
}
.cycle-cell-inner {
  width: 15%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  /* padding: 0.094rem 2.313rem 0 0; */
  box-sizing: border-box;
  font-size: 1rem;
  font-family: Poppins;
}
.cycle-frame {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 1.875rem;
  margin-top: 8rem;
  width: 100%;
}
