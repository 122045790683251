.products-list8 {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
}
.edit-icon3 {
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
}
.frame-products-list-text-insta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0.063rem;
}
.i-n-s {
  margin-left: -0.312rem;
  position: relative;
  text-decoration: underline;
  font-weight: 500;
  white-space: nowrap;
}
.input-field-frame-label-frame,
.text4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field-frame-label-frame {
  cursor: pointer;
  align-items: flex-end;
  gap: 0 0.313rem;
  text-align: right;
  font-size: 0.875rem;
  color: #454551;
}
.products-list-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 1.25rem;
}
.button-frame1,
.l-i-n-e1 {
  align-self: stretch;
  box-sizing: border-box;
}
.button-frame1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1.313rem 0 1.5rem;
  max-width: 100%;
}
.l-i-n-e1 {
  height: 0.063rem;
  position: relative;
  border-top: 1px solid #e2e2ea;
}
.label-custom {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  font-family: 'Poppins';
  font-size: 1rem;
}
.input-field-label-frame,
.l-a-b-e-l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.l-a-b-e-l {
  border-radius: 10px;
  gap: 0 0.25rem;
}
.input-field-label-frame {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 1.5rem;
  flex: 1;
  font-family: Lexend;
  font-weight: 300;
  font-size: 1rem;
  color: #100604;
  min-width: 9.313rem;
}
.i-n-s-t-a-n-c-e {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field7 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.label8 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
}
.label-parent1 {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.25rem;
}
.placeholder4 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
}
.frame-parent5 {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field8,
.products-list-input-field-grou {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field8 {
  flex: 1;
  flex-direction: column;
  gap: 0.5rem 0;
  min-width: 11.375rem;
}
.products-list-input-field-grou {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 2.5rem;
}
.label9 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
}
.input-field-container {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.25rem;
}
.placeholder5 {
  width: 12rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #727272;
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding: 1rem 2rem 1rem 1rem;
  gap: 0 0.5rem;
}
.text-instance {
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0.938rem;
  gap: 0 0.5rem;
}
.input-field9 {
  width: 17.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
}
.button-frame-parent,
.f-r-a-m-e,
.f-r-a-m-e-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.f-r-a-m-e {
  flex: 1;
  flex-direction: column;
  gap: 1.5rem 0;
  z-index: 1;
}
.button-frame-parent,
.f-r-a-m-e-wrapper {
  box-sizing: border-box;
}
.f-r-a-m-e-wrapper {
  width: 40.5rem;
  flex-direction: row;
  padding: 0 1.5rem;
  color: #100604;
  font-family: Lexend;
}
.button-frame-parent {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  flex-direction: column;
  padding: 1.25rem 0 1.625rem;
  gap: 1.25rem 0;
  z-index: 1;
  text-align: left;
  font-size: 1rem;
  color: #171725;
  font-family: Poppins;
}
@media screen and (max-width: 650px) {
  .products-list-input-field-grou {
    gap: 0 1.25rem;
  }
}
@media screen and (max-width: 450px) {
  .products-list-parent {
    flex-wrap: wrap;
  }
}
