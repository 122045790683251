.warning-modal {
    padding: 10px 20px;
    border-radius: 5px;
    width: 60vh;
    min-height: 13vh;
}

.header-text {
    color: #760FB2;
    font-size: 25px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

.warning-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
}

.warning-text {
    font-size: 15px;
    margin: 5px -10px;
    font-weight: 600;
    color: black;
}

.proceed-button-container {
    width: 100%;
    margin: 10px 0px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-button {
    background-color: white;
    color: #760FB2;
    border: 1px solid #760FB2;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.warning-header {
    background-color: #760FB2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.proceed-button {
    margin-left: 10px;
    background-color: #760FB2 !important;
}