.records-page-container {
  .page-title {
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 16px;
  }
  
  .table-name-col {
    max-width: 200px;
  }
}
