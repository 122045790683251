.contact-frame,
.name {
  position: relative;
  letter-spacing: 0.1px;
}
.contact-frame {
  width: 1.375rem;
  line-height: 1.625rem;
  display: inline-block;
  flex-shrink: 0;
}
.name {
  font-size: 1rem;
  font-weight: 500;
  font-family: Poppins;
  color: #4b4ad5;
}
.contact-frame-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem;
}
.appointment-cell-inner {
  width: 14%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  /* padding: 0.094rem 2.313rem 0 0; */
  box-sizing: border-box;
  font-size: 1rem;
  font-family: "Poppins";
}
.contact {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
  white-space: nowrap;
}
.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.094rem 0 0;
}
.contact1 {
  position: relative;
  letter-spacing: 0.1px;
  font-weight: 500;
}
.contact-container {
  width: 6.213rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.094rem 0 0;
  box-sizing: border-box;
}
.visit-type {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
}
.program-type {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  padding-right: 10px;
}
.visit-type-wrapper {
  width: 9.213rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.094rem 0 0;
  box-sizing: border-box;
  color: #4b4ad5;
  font-family: Manrope;
}
.cycle-status-cell-inner {
  width: 15%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  /* padding: 0.094rem 2.313rem 0 0; */
  box-sizing: border-box;
  font-size: 1rem;
  font-family: Poppins;
}
.status-title {
  color: #00006a;
}
.status-button {
}
.edit-date-container {
  border: none;
  outline: none;
  padding: 10px 40px 10px 0;
  font-size: 1rem;
}
/* Hide the default calendar icon */
.edit-date-container input[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Style the edit icon */
.edit-icon {
  width: 10px; /* Adjust the width as needed */
  height: 10px; /* Adjust the height as needed */
  cursor: pointer;
  position: relative;
  /* Add any additional styling for the edit icon */
}
.googleinc,
.googleinc1 {
  position: relative;
  letter-spacing: 0.1px;
}
.googleinc {
  font-weight: 500;
  white-space: nowrap;
}
.googleinc1 {
  line-height: 1.625rem;
}
.appointment-cell-child,
.googleinc-parent,
.googleinc-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.googleinc-wrapper {
  height: 1.25rem;
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-family: Roboto;
}
.appointment-cell-child,
.googleinc-parent {
  flex-direction: column;
}
.appointment-cell-child {
  padding: 0.094rem 1.575rem 0 0;
}
.gender-age,
.primary-button {
  position: relative;
  font-size: 0.875rem;
}
.gender-age {
  width: 4.75rem;
  letter-spacing: 0.1px;
  line-height: 1.625rem;
  font-family: Roboto;
  display: none;
}
.primary-button {
  font-weight: 600;
  font-family: Poppins;
  color: #4b4ad5;
  text-align: left;
}
.secondary-default {
  cursor: pointer;
  border: 1px solid #4b4ad5;
  padding: 0.625rem 0.875rem;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}
.secondary-default:hover {
  background-color: #e6e6e6;
  border: 1px solid #7070fc;
  box-sizing: border-box;
}
.more-icon {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}
.appointment-cell,
.more-wrapper,
.secondary-default-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.appointment-cell:hover {
  background-color: #efefef;
}
.more-wrapper {
  flex-direction: column;
  padding: 0.531rem 0 0;
}
.appointment-cell,
.secondary-default-parent {
  flex-direction: row;
  /* gap: 0 1.25rem; */
}
/* .appointment-cell {
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 6px 1rem;
  box-sizing: border-box;
  width: 1133px;
  text-align: left;
  font-size: 1rem;
  color: #454551;
  font-family: Poppins;
} */
.appointment-cell {
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 6px 1rem;
  box-sizing: border-box;
  /* width: 1133px; */
  text-align: left;
  font-size: 1rem;
  color: #454551;
  font-family: Poppins;
  width: 84%;
  position: relative;
}
