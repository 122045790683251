.report-list-container {
    display: flex;
    flex-direction: column;
    width: 97%;
    gap: 10px;
    margin: auto;
    background-color: white;
    min-height: 700px;

    .report-list-heading {
        font-size: 25px;
        font-weight: 700;
        color: #760FB2;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 20px;
    }

    .report-container {
        background: white;
        box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
        width: 97%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        flex-direction: column;

        .report-date-title {
        margin-left: 10px;
            display: flex;
            flex-direction: column;

            .report-title {
                font-size: 15px;
                font-weight: 600;
            }

            .report-date {
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}