.cycle-details-container {
  width: 40.5rem;
  flex-direction: row;
  padding: 0 1.5rem;
  color: #100604;
  font-family: Lexend;
  /* margin-left: 2.8rem; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  box-sizing: border-box;
}
.desktop-3-child {
  width: 100%;
  height: 12.5rem;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.arrow-left {
  width: 2rem;
  height: 2rem;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.frame-f-r-a-m-e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.15rem 0 0;
}
.products-list1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.1px;
  line-height: 2.5rem;
  font-weight: 600;
  font-family: inherit;
  white-space: nowrap;
  z-index: 1;
  color: black;
}
.rectangle-v-e-c-t-o-r {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 0.625rem;
}
.huge-iconuseroutlineuser {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5rem;
}
.button1 {
  flex: 1;
  position: relative;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}
.huge-iconarrowsoutlinedirec {
  height: 1.5rem;
  width: 2.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5rem;
}
.button-frame {
  flex: 1;
  border-radius: 10px;
  flex-direction: row;
  /* gap: 0 0.5rem; */
}
.button,
.button-frame,
.frame-f-r-a-m-e1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 0.75rem 0.25rem;
  background-color: #4b4ad5;
  align-self: stretch;
  border-radius: 10px;
  flex-direction: row;
  z-index: 1;
}
.frame-f-r-a-m-e1 {
  width: 13.875rem;
  flex-direction: column;
  padding: 0.063rem 0 0;
  box-sizing: border-box;
  /* position: absolute;
  right: 0%;
  bottom: -5%; */
}

/* check box */
.checkbox-frame {
  width: 22.875rem;
  flex-direction: column;
  padding: 0.063rem 0 0;
  box-sizing: border-box;
  right: 2%;
  bottom: 25%;
  display: flex;
}
.checkbox {
  z-index: 2;
}

.checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
}

/* .checkbox.checked .checkmark {
  background-color: #2196f3;
} */

.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
  border-radius: 5px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 28%;
  top: 3%;
  width: 6px;
  height: 12px;
  border: solid white;
  /* border-radius: 1px; */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.label-checkbox {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  /* font-weight: 300; */
  font-family: "Poppins";
  font-size: 1.1rem;
  margin-left: 2rem;
}

.container-f-r-a-m-e {
  width: 83.75rem;
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.5rem;
  color: #171725;
  font-family: Poppins;
}
.input-field-with-label-and-pla,
.line-l-i-n-e {
  flex: 1;
  flex-direction: column;
  gap: 1.875rem 0;
  min-width: 27.438rem;
  max-width: 100%;
  position: relative;
}
.desktop-3,
.group-f-r-a-m-e,
.input-field-with-label-and-pla,
.line-l-i-n-e {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.group-f-r-a-m-e {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 1.875rem;
  max-width: 100%;
}
.desktop-3 {
  width: 100%;
  position: relative;
  background-color: #fafafb;
  overflow: hidden;
  flex-direction: column;
  padding: 2.438rem 1.875rem 4.375rem;
  box-sizing: border-box;
  gap: 2.063rem 0;
  letter-spacing: normal;
}
.create-journey {
  width: 100%;
  display: flex;
  justify-content: center;
}
.confirmation-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
}
.overlay {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  position: fixed;
}
.visible {
  opacity: 1;
  pointer-events: auto;
}
.vivitra-status-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem 0;
}
.vivitra-sub-title {
  font-size: 1.3rem;
  color: #18181c;
  margin-left: 2.5rem;
}
.vivitra {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  /* gap: 1rem; */
  border: 1px solid #5e5e5e1f;
  border-radius: 1rem;
  padding: 0 0 1rem;
  margin-top: -2rem;
}
.vivitra-container {
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
}
.vivitra-address {
  width: 25%;
}
.vivitra-status {
  width: 25%;
}
.status-date {
  width: 25%;
}
.vivitra-placeHolder {
  width: 100%;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #868383;
  padding: 0.5rem;
  padding-right: 1rem;
  gap: 0 3.5rem;
  outline: none;
}
.address-field-container{
  display: flex;
}
.save-address-icon {
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
  top: 46%;
  left: 31%;
  z-index: 3;
  cursor: pointer;
}
.vivitra-select {
  width: 13.5rem;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  color: #868383;
  padding: 0.5rem;
  gap: 0 0.5rem;
}
.vivitra-label {
  width: 100%;
  font-size: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 0.25rem;
}
.vivitra-btn {
  cursor: pointer;
  background-color: #4b4ad5;
  border: 1px solid #4b4ad5;
  color: rgb(255, 255, 255) !important;
  padding: 0.3rem 1rem;
  margin-left: 1.5rem;
  border-radius: 8px;
  margin-right: 8px;
}
.vivitra-btn-disabled {
  cursor: pointer;
  background-color: #9a9ab3;
  border: 1px solid #9c9ca4;
  color: rgb(236, 235, 235) !important;
  padding: 0.3rem 1rem;
  margin-left: 1.5rem;
  border-radius: 8px;
  margin-right: 8px;
}
@media screen and (max-width: 725px) {
  .desktop-3 {
    gap: 1rem 0;
  }
}
@media screen and (max-width: 650px) {
  .input-field-with-label-and-pla,
  .line-l-i-n-e {
    min-width: 100%;
  }
}
