.upload-records-modal-container {
  .upload-records-form {
    .search_bar {
      margin-bottom: 1rem;
      width: 100%;
      .label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 20px;
        color: #760fb2;
        margin-top: 0;
        padding: 0;
        padding-left: 8px;
      }
      .search-input {
        font-family: "Poppins" !important;
        box-sizing: border-box;
        padding: 16px;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.2s ease;

        &:focus {
          border-color: #760fb2;
        }

        &[aria-invalid="true"] {
          border-color: #e53935;
        }
      }
    }
    .select-container {
      width: 100%;
    }
  }
  .upload-records-footer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 2rem;
  }
}
