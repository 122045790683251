.assessment-view-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin: auto;
    // box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
    min-height: 100vh;
    gap: 20px;
    margin: 20px 0;

    .assessment-view-inner {
        display: flex;
        flex-direction: column;
        padding: 8px;
        width: 94%;
        //box-shadow: 1px 1px 10px 5px rgb(241, 239, 239);
        margin-left: 20px;

        .assessment-heading {
            font-size: 22px;
            font-weight: 700;
            color: #8129B9;
            width: 100%;
        }

        .assessment-view-personal {
            display: flex;
            flex-direction: column;
            width: 100%;

            .assessment-view-personal-heading {
                background-color: #EBDBF4;
                font-size: 18px;
                text-align: center;
                font-weight: 600;
                padding: 10px;
                color: #8129B9
            }

            .personal-view-que-ans {
                display: flex;
                flex-direction: column;
                width: 100%;
        
                .personal-view-que-ans-inner {
                    display: flex;
                    // align-items: center;
        
                    .personal-view-que-ans-left {
                        width: 200px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 15px;
                        font-weight: 600;
                        color: #8129B9;
                        // align-items: center;
                    }

                    .personal-view-que-ans-right{
                        display: flex;
                        font-size: 14px;
                        font-weight: 600;
                        align-items: center;
                        margin-left: 20px;
                        // text-align: center;
                        width:600px
                    }
                }
            }
        }

        .assessment-view-other {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 30px;
            gap: 20px;
        
            .assessment-view-other-heading {
                background-color: #EBDBF4;
                font-size: 18px;
                font-weight: 600;
                padding: 20px 5px;
                color: #8129B9;
                text-align: center;
            }
        
            .assessment-view-que-ans {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 5px;
                margin-top: 10px;
        
                .assessment-view-que {
                    font-size: 15px;
                    font-weight: 600;
                    color: #8129B9;
                }

                .assessment-view-ans{
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 20px;
                }
        
            }
        }
    }
}